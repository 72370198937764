import axios from 'axios';
import { guestId } from './uuid';
import { getToken } from './auth';
import errorHandler from './errorHandler';

export const getMode = (returnBool = true) => {
  const isLocal = /ngrok|localhost|fazenda-front.test|127.0.0.1/.test(window.location.hostname);

  if (isLocal) {
    if (returnBool) return true;
    return 'local';
  }

  const isDevelopmentEnvironmentEduardo = /fazenda-eduardo.test/.test(window.location.hostname);

  console.log(isDevelopmentEnvironmentEduardo);
  if (isDevelopmentEnvironmentEduardo) {
    if (returnBool) return true;
    return 'development';
  }

  const isDevelopmentEnvironment = /ngrok|loca.lt|development.wsharefazendas.com.br/.test(
    window.location.hostname
  );

  if (isDevelopmentEnvironment) {
    if (returnBool) return true;
    return 'development';
  }

  if (window.location.hostname === 'staging.wsharesistemas.com.br') {
    return 'homologation';
  }

  if (returnBool) return false;
  return 'production';
};

const apiMode = getMode(false);
const tenantData = JSON.parse(sessionStorage.getItem('tenant_data'));
let tenantUUID = tenantData?.uuid;

const apiUrl = tenantData?.apiUrl;

const apiHost = {
  local: 'fazenda-front.test:3000',
  development: 'wsharesistemas.com.br',
  homologation: 'wsharesistemas.com.br',
  production: 'wsharesistemas.com.br'
};

const apiProtocol = {
  local: 'http',
  development: 'https',
  homologation: 'https',
  production: 'https'
};

const apiEndpoint = {
  local: 'model1.fazenda.test/api',
  development: 'model1.wsharesistemas.com.br/api',
  homologation: 'model1.wsharesistemas.com.br/api',
  production: 'wsharesistemas.com.br/api'
};

const apiVersion = {
  local: 'v2',
  development: 'v2',
  homologation: 'v2',
  production: 'v2'
};

export const isExistsModelByHostname = () => {
  const subdomain = window.location.hostname.split('.')[0];
  const hostnameForEnv = apiHost[apiMode].split('.')[0];

  if (subdomain === hostnameForEnv) {
    return false;
  }

  return true;
};

export async function getFullEndPoint(tenancyAPI = false) {
  if (apiUrl && apiMode !== 'local') {
    return `${apiUrl}/api/${apiVersion[apiMode]}`;
  }

  if (!tenantUUID) {
    tenantUUID = window.location.hostname.split('.')[0];
  }

  if (tenancyAPI) {
    return `${apiProtocol[apiMode]}://${apiEndpoint[apiMode]}/${apiVersion[apiMode]}`;
  }

  switch (apiMode) {
    case 'local':
      return `${apiProtocol.local}://${apiEndpoint.local}/${apiVersion.local}`;

    case 'production':
      return `${apiProtocol[apiMode]}://${tenantUUID}.${apiEndpoint[apiMode]}/${apiVersion[apiMode]}`;

    case 'development':
      return `${apiProtocol[apiMode]}://${apiEndpoint[apiMode]}/${apiVersion[apiMode]}`;

    case 'homologation':
      return `${apiProtocol[apiMode]}://backend.${apiEndpoint[apiMode]}/${apiVersion[apiMode]}`;

    default:
      return `${apiProtocol[apiMode]}://back.${apiEndpoint[apiMode]}/${apiVersion[apiMode]}`;
  }
}

export async function getFullHost() {
  if (apiUrl) {
    return `${apiUrl}/api/${apiVersion[apiMode]}`;
  }

  if (!tenantUUID) {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    tenantUUID = JSON.parse(sessionStorage.getItem('tenant_data'))?.uuid;
  }

  switch (apiMode) {
    case 'local':
      return `${apiProtocol.local}://${apiHost.local}`;

    case 'production':
      return `${apiProtocol[apiMode]}://${tenantUUID}.${apiHost[apiMode]}`;

    default:
      return `${apiProtocol[apiMode]}://back.${apiHost[apiMode]}`;
  }
}

const api = axios.create();

api.defaults.withCredentials = true;

api.interceptors.request.use(async (config) => {
  const token = getToken();
  const apiConfig = config;

  apiConfig.baseURL = await getFullEndPoint();

  if (token) {
    apiConfig.headers = {
      Authorization: `Bearer ${token}`,
      'X-Requested-With': 'XMLHttpRequest',
      'X-GUEST-ID': guestId()
    };
  } else {
    apiConfig.headers = {
      'X-Requested-With': 'XMLHttpRequest',
      'X-GUEST-ID': guestId()
    };
  }

  apiConfig.withCredentials = false;

  return apiConfig;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    errorHandler(error);
    return Promise.reject(error);
  }
);

export default api;
