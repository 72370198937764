import React, { useEffect, useState, useRef } from 'react';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import * as moment from 'moment';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown,
  Input,
  FormFeedback,
  Label,
  Form
} from 'reactstrap';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import Message from 'components/Message';

// Import Breadcrumb
import Breadcrumbs from 'components/Common/Breadcrumb';

import { showMessage, hideMessage } from 'store/message/actions';

import DeleteModal from '../../components/Common/DeleteModal';
import {
  getPermissions as onGetPermissions,
  addNewPermission as onAddNewPermission,
  updatePermission as onUpdatePermission,
  deletePermission as onDeletePermission
} from 'store/permissions/actions';

// redux
import { useSelector, useDispatch } from 'react-redux';
import Can from 'components/Can';
import { withTranslation } from 'react-i18next';

const Permissions = (props) => {
  const dispatch = useDispatch();

  const { permissions } = useSelector((state) => ({
    permissions: state.Permission.permissions
  }));

  const { error } = useSelector(function (state) {
    return { error: state.Permission.error };
  });
  const [messageAlert, setMessageAlert] = useState('');
  const [typeMessage, setTypeMessage] = useState('');

  const [modal, setModal] = useState(false);
  const [permissionList, setPermissionList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [permission, setPermission] = useState(null);

  useEffect(() => {
    if (error != null && Object.keys(error).length !== 0) {
      showingMessage(error.message, 'warning');
    }
  }, [error]);

  useEffect(async () => {
    await dispatch(onGetPermissions());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(permissions)) {
      setPermissionList(permissions);
    }
  }, [permissions]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (permission && permission.name) || '',
      description: (permission && permission.description) || ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please Enter Your Name'),
      description: Yup.string().required('Please Enter With Description')
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updatePermission = {
          id: permission ? permission.id : 0,
          name: values.name,
          discription: values.description
        };
        // update permission
        dispatch(onUpdatePermission(updatePermission));
        validation.resetForm();

        handleAsyncActions('Updated');
      } else {
        const newPermission = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          name: values['name'],
          description: values['description']
        };
        // save new permission
        dispatch(onAddNewPermission(newPermission));
        validation.resetForm();

        handleAsyncActions('Added');
      }
      toggle();
    }
  });

  // pagination customization
  const pageOptions = {
    totalSize: permissions.length, // replace later with size(orders),
    custom: true
  };

  const PermissionColumns = [
    {
      text: 'id',
      dataField: 'id',
      sort: true,
      hidden: false,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.id}</>
    },
    {
      dataField: 'name',
      text: `${props.t('name')}`,

      sort: true
    },
    {
      dataField: 'description',
      text: `${props.t('description')}`,

      sort: true
    },
    {
      dataField: 'button-actions',
      text: `${props.t('action')}`,

      formatter: (cellContent, permission) => (
        <div className="btn-group float-end gap-2">
          <Can permissions={['edit_permissions']}>
            <Button
              type="button"
              color="warning"
              size="sm"
              className=""
              onClick={() => handlePermissionClick(permission)}>
              <i className="fa fa-pen" />
            </Button>
          </Can>
          <Can permissions={['destroy_permissions']}>
            <Button
              type="button"
              color="danger"
              size="sm"
              className=""
              onClick={() => onClickDelete(permission)}>
              <i className="fa fa-trash" />
            </Button>
          </Can>
        </div>
      )
    }
  ];

  const toggle = () => {
    if (modal) {
      setModal(false);
      setPermission(null);
    } else {
      setModal(true);
    }
  };

  const handlePermissionClick = (arg) => {
    const permission = arg;

    setPermission({
      id: permission.id,
      name: permission.name,
      description: permission.description
    });

    setIsEdit(true);
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  // delete permission
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (permission) => {
    setPermission(permission);
    setDeleteModal(true);
  };

  const handleDeletePermission = () => {
    if (permission.id) {
      dispatch(onDeletePermission(permission));
      onPaginationPageChange(1);
      setDeleteModal(false);

      handleAsyncActions('Deleted');
    }
  };

  const { SearchBar } = Search;

  const handleAsyncActions = (action) => {
    if (error == null || Object.keys(error).length === 0) {
      showingMessage(`Permission ${action} Successfully`, 'success');
    }
  };

  const showingMessage = (title, type) => {
    setMessageAlert(title);
    setTypeMessage(type);

    dispatch(showMessage());
    setTimeout(() => {
      dispatch(hideMessage());
    }, 2000);
  };

  const handleTableChange = (type, { page, searchText }) => {
    setPermissionList(
      permissions.filter((permission) =>
        Object.keys(permission).some((key) =>
          permission[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    );
  };

  const handlePermissionClicks = () => {
    setPermissionList('');
    setIsEdit(false);
    toggle();
  };

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'asc'
    }
  ];

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format('DD MMM Y');
    return date1;
  };

  return (
    <React.Fragment>
      <Message title={messageAlert} type={typeMessage} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeletePermission}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <MetaTags>
          <title>Permission | SistemasW Fazenda</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title={props.t('Permissions')} breadcrumbItem={props.t('Permissions')} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={PermissionColumns}
                    data={permissions}>
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={permissions || []}
                        columns={PermissionColumns}
                        bootstrap4
                        search>
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box ms-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Can permissions={['create_permissions']}>
                                    <Button
                                      type="button"
                                      color="success"
                                      className="btn-rounded  mb-2 me-2"
                                      onClick={handlePermissionClicks}>
                                      <i className="mdi mdi-plus me-1" />
                                      {props.t('New Permission')}
                                    </Button>
                                  </Can>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              {/* <div className="pagination-size-dropdown">
                                <SizePerPageDropdownStandalone {...paginationProps} />
                              </div> */}
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={'table align-middle table-nowrap'}
                                    keyField="id"
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                    ref={node}
                                  />
                                </div>
                                <Modal isOpen={modal} toggle={toggle}>
                                  <ModalHeader toggle={toggle} tag="h4">
                                    {!!isEdit ? 'Edit Permission' : 'Add Permission'}
                                  </ModalHeader>
                                  <ModalBody>
                                    <Form
                                      onSubmit={(e) => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        return false;
                                      }}>
                                      <Row form>
                                        <Col className="col-12">
                                          <div className="mb-3">
                                            <Label className="form-label">
                                              {props.t('Name Permission')}
                                            </Label>
                                            <Input
                                              name="name"
                                              type="text"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.name || ''}
                                              invalid={
                                                validation.touched.name && validation.errors.name
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {validation.touched.name && validation.errors.name ? (
                                              <FormFeedback type="invalid">
                                                {validation.errors.name}
                                              </FormFeedback>
                                            ) : null}
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row form>
                                        <Col className="col-12">
                                          <div className="mb-3">
                                            <Label className="form-label">
                                              {props.t('Description')}
                                            </Label>
                                            <Input
                                              name="description"
                                              type="text"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.description || ''}
                                              invalid={
                                                validation.touched.description &&
                                                  validation.errors.description
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {validation.touched.description &&
                                              validation.errors.description ? (
                                              <FormFeedback type="invalid">
                                                {validation.errors.description}
                                              </FormFeedback>
                                            ) : null}
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <div className="text-end">
                                            <button
                                              type="submit"
                                              className="btn btn-success save-permission">
                                              {props.t('Save')}
                                            </button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Form>
                                  </ModalBody>
                                </Modal>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-start mb-2 inner-custom-pagination">
                                <SizePerPageDropdownStandalone {...paginationProps} />
                              </Col>
                              <Col>
                                <div className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                  <PaginationListStandalone {...paginationProps} />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Permissions.propTypes = {
  permissions: PropTypes.array,
  onGetPermissions: PropTypes.func,
  onAddNewPermission: PropTypes.func,
  onDeletePermission: PropTypes.func,
  onUpdatePermission: PropTypes.func
};

export default withTranslation()(Permissions);
