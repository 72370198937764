import React, { useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import * as url from '../../helpers/url_helper';

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Input,
  FormGroup,
  Label,
  Button,
  Form,
  FormFeedback
} from 'reactstrap';
import Dropzone from 'react-dropzone';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-datepicker/dist/react-datepicker.css';
import Message from '../../components/Message';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { showMessage, hideMessage } from 'store/message/actions';
import {
  getBreeds as onGetBreeds,
  getIrons as onGetIrons,
  getMovementTypes as onGetMovementTypes,
  getFarms as onGetFarms
} from 'store/actions';
import { sendBancoDadosPrice } from 'utils/inputMask';
import formatBytes from 'utils/formatBytes';
import axiosApi from 'helpers/api_helper';
import { formatDateByMoment } from 'utils/formats';
import MediaLibrary from 'components/MediaLibrary/MediaLibrary';
import useMediaLibraryStore from 'zustand/media-library/file-upload-store';
import { withTranslation } from 'react-i18next';
import ReactInputMask from 'react-input-mask';
import { CollectService, useCollectsStore } from 'zustand/collects/collects-store';

const configHeaderFile = {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
};

const MovementCreate = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const inpRow = [];
  const [startDate, setstartDate] = useState(new Date());
  const [endDate, setendDate] = useState(new Date());
  const [animals, setanimals] = useState(inpRow);

  const optionGroupEmpty = [
    {
      label: 'Breeds',
      options: []
    }
  ];

  const [earringErrors, setEarringErrors] = useState({});
  const { isLoading, setIsLoading } = useCollectsStore();

  const [isDropFile, setIsDropFile] = useState(false);
  const [selectedBreed, setSelectedBreed] = useState(null);
  const [optionGroupBreed, setOptionGroupBreed] = useState(optionGroupEmpty);

  const [selectedIron, setSelectedIron] = useState(null);
  const [optionGroupIron, setOptionGroupIron] = useState(optionGroupEmpty);

  const [selectedMovementType, setSelectedMovementType] = useState(null);
  const [optionGroupMovementType, setOptionGroupMovementType] = useState(optionGroupEmpty);

  const [selectedFarm, setSelectedFarm] = useState(null);
  const [optionGroupFarm, setOptionGroupFarm] = useState(optionGroupEmpty);

  const [movement, setMovement] = useState(null);
  const [movementTypeId, setMovementTypeId] = useState(null);

  const [selectedFiles, setselectedFiles] = useState([]);

  const { breeds } = useSelector((state) => ({ breeds: state.Breed.breeds }));
  const { irons } = useSelector((state) => ({ irons: state.Iron.irons }));
  const { movementTypes } = useSelector((state) => ({
    movementTypes: state.MovementType.movementTypes
  }));
  const { farms } = useSelector((state) => ({ farms: state.Farm.farms }));

  const { error } = useSelector((state) => ({ error: state.Movement.error }));
  // const { errorFarm } = useSelector((state) => ({ errorFarm: state.Farm.error }));

  // MediaLibrary
  const { dataUpload } = useMediaLibraryStore();

  const [messageAlert, setMessageAlert] = useState('');
  const [typeMessage, setTypeMessage] = useState('');

  useEffect(() => {
    if (error != null && Object.keys(error).length !== 0) {
      showingMessage(error.message, 'warning');
    }
  }, [error]);

  useEffect(() => {
    dispatch(onGetBreeds());
    dispatch(onGetIrons());
    dispatch(onGetMovementTypes());
    dispatch(onGetFarms());
  }, []);

  useEffect(() => {
    setOptionGroupBreed(formatDataForSelect('Breeds', breeds));
  }, [breeds]);

  useEffect(() => {
    setOptionGroupFarm(formatDataForSelect('Farms', farms));
  }, [farms]);

  useEffect(() => {
    setOptionGroupIron(formatDataForSelect('Irons', irons));
  }, [irons]);

  useEffect(() => {
    setOptionGroupMovementType(
      formatDataForSelect(
        'Movement Types',
        movementTypes.filter((item) => item.operation == 'E')
      )
    );
  }, [movementTypes]);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      collectName: (movement && movement.collectName) || '',
      amount: (movement && movement.amount) || '',
      freight: (movement && movement.freight) || '',
      weight: (movement && movement.weight) || '',
      other_values: (movement && movement.other_values) || '',
      arroba_price: (movement && movement.arroba_price) || ''
    },
    validationSchema: Yup.object({
      collectName: Yup.string().required('Por favor, insira o nome da coleta'),
      amount: Yup.string().required('Por favor, insira o valor total da coleta'),
      freight: Yup.string().required('Por favor, insira o valor do frete da coleta'),
      other_values: Yup.string().required('Por favor, insira outros valores da coleta'),
      arroba_price: Yup.string().required('Por favor, insira o preço da arroba da coleta')
    }),
    onSubmit: (values) => {
      if (Object.keys(earringErrors).length > 0) {
        showingMessage('Corrija os erros nos brincos antes de salvar', 'warning');
        return;
      }

      if (isDropFile && selectedFiles.length <= 0) {
        showingMessage('Inserir arquivo obrigatório', 'warning');
        return;
      }

      if (animals.length > 0) {
        for (var i = 0; animals.length >= i; i++) {
          let animal = animals[i];

          if (animal?.weight.length <= 0) {
            showingMessage('Preencha os campos do animal', 'warning');
            return;
          }
        }
      }

      if (!isDropFile && animals.length <= 0) {
        showingMessage('Obrigatório selecionar Importar ou Adicionar animal', 'warning');
        return;
      }

      const formData = new FormData();

      if (dataUpload.imagesForUpload.length) {
        dataUpload.imagesForUpload.map((dataFile) => formData.append(`fotos[]`, dataFile));
      }

      const animalsConvertPrice = animals.map((row) => {
        row.weight = sendBancoDadosPrice(row.weight);
        return row;
      });

      formData.append('timestamp', (Date.now() / 1000) | 0);
      formData.append('movement_type_id', movementTypeId);
      formData.append('farm_id', selectedFarm.value);
      formData.append('movement_date', formatDateByMoment(startDate));
      formData.append('collect[name]', values.collectName);
      formData.append('collect[amount]', sendBancoDadosPrice(values.amount));
      formData.append('collect[freight]', sendBancoDadosPrice(values.freight));
      formData.append('collect[other_values]', sendBancoDadosPrice(values.other_values));
      formData.append('collect[arroba_price]', sendBancoDadosPrice(values.arroba_price));

      if (!isDropFile) {
        formData.append('collect[animals]', JSON.stringify(animalsConvertPrice));
      } else {
        formData.append('file', selectedFiles[0]);
      }

      tryServerApi(formData, !isDropFile ? url.ADD_NEW_MOVEMENT : url.ADD_NEW_MOVEMENT_FILE);
    }
  });

  const tryServerApi = async (movementData, URL) => {
    try {
      const response = await axiosApi.post(URL, movementData, configHeaderFile);
      const { message } = response.data;
      showingMessage(message, 'success');
      setTimeout(() => {
        //  history.push('/movements');
      }, 2000);
    } catch (error) {
      console.log(error);
      const { message } = error.response.data;
      showingMessage(message, 'warning');
    }
  };

  function formatDataForSelect(title, data = []) {
    if (data && data.length > 0) {
      return [
        {
          label: title,
          options: data.map((item) => {
            return { label: item.name, value: item.id };
          })
        }
      ];
    }
    return [
      {
        label: title,
        options: []
      }
    ];
  }

  function handleAddFields() {
    const item1 = {
      iron_id: '',
      breed_id: '',
      earring: '',
      earring_eletric: '',
      nickname: '',
      obs: '',
      weight: '',
      note: ''
    };
    setanimals([...animals, item1]);
  }

  const showingMessage = (title, type) => {
    setMessageAlert(title);
    setTypeMessage(type);

    dispatch(showMessage());
    setTimeout(() => {
      dispatch(hideMessage());
    }, 2000);
  };

  function handleRemoveFields(idx) {
    const alredySelected = animals.findIndex((item, key) => key === idx);

    if (alredySelected >= 0) {
      const filtered = animals.filter((item, key) => key !== idx);

      setanimals(filtered);
      document.getElementById('nested' + idx).style.display = 'none';
    }
  }

  function handleSelectBreed(selectedBreed) {
    setSelectedBreed(selectedBreed);
  }

  function handleSelectIron(selectedIron) {
    setSelectedIron(selectedIron);
  }

  function handleSelectFarm(selectedFarm) {
    setSelectedFarm(selectedFarm);
  }

  function handleSelectMovementType(selectedMovementType) {
    setSelectedMovementType(selectedMovementType);
    setMovementTypeId(selectedMovementType.value);
  }

  function handleAddBreed(idx, value) {
    let newanimals = [...animals];
    newanimals[idx].breed_id = value;
    setanimals(newanimals);
  }

  function handleAddIron(idx, value) {
    let newanimals = [...animals];
    newanimals[idx].iron_id = value;
    setanimals(newanimals);
  }

  function handleAddEarring(idx, value) {
    let newanimals = [...animals];
    newanimals[idx].earring = value;
    setanimals(newanimals);
  }

  function handleAddEarringEletric(idx, value) {
    let newanimals = [...animals];
    newanimals[idx].earring_eletric = value;
    setanimals(newanimals);
  }

  function handleAddNote(idx, value) {
    let newanimals = [...animals];
    newanimals[idx].note = value;
    setanimals(newanimals);
  }

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size)
      })
    );

    setselectedFiles(files);
  }

  function handleChangeFilesMediaLibrary(dataUpload) {
    console.log({ dataUpload });
  }

  const handleDateChange = (e) => {
    console.log('date', e.target.value);
    setstartDate(e.target.value);
    const value = e.target.value.replace(/\D/g, '');
    const formattedValue = value.replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3');
    validation.handleChange({
      target: {
        name: e.target.name,
        value: formattedValue
      }
    });
  };

  const formatNumber = (value) => {
    if (!value) return '';
    const cleanedValue = value.replace(/\D/g, '');
    const formattedValue = new Intl.NumberFormat('pt-BR', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(cleanedValue / 100);
    return formattedValue;
  };

  const handleNumberChange = (e) => {
    console.log('event', e);
    const value = e.target.value;
    const formattedValue = formatNumber(value);
    validation.handleChange({
      target: {
        name: e.target.name,
        value: formattedValue
      }
    });
  };

  const handleNumberChangeAdd = (idx, e) => {
    let newanimals = [...animals];
    const value = e.target.value;
    const formattedValue = formatNumber(value);
    newanimals[idx].weight = formattedValue;
    setanimals(newanimals);
    validation.handleChange({
      target: {
        name: `${idx}${e.target.name}`,
        value: formattedValue
      }
    });
  };

  const validateEarring = async (idx, value) => {
    let newErrors = { ...earringErrors };

    setIsLoading(true);

    // Verifica duplicatas no array
    const isDuplicate = animals.some(
      (animal, index) => index !== idx && animal.earring === value && value !== ''
    );

    if (isDuplicate) {
      newErrors[idx] = 'Brinco duplicado';
      setEarringErrors(newErrors);
      return;
    }

    // Verifica via API
    try {
      const collectService = new CollectService();

      await collectService.checkEarring(value);
      // Se a API retornar sucesso, remove o erro
      delete newErrors[idx];
    } catch (error) {
      // Se a API retornar erro (400 ou outro)
      newErrors[idx] = error.response?.data?.message || 'Brinco inválido';
    } finally {
      setIsLoading(false);
    }

    setEarringErrors(newErrors);
    handleAddEarring(idx, value);
  };

  function handleRemoveFields(idx) {
    const alredySelected = animals.findIndex((item, key) => key === idx);

    if (alredySelected >= 0) {
      const filtered = animals.filter((item, key) => key !== idx);
      setanimals(filtered);

      // Remove erro associado ao índice removido
      const newErrors = { ...earringErrors };
      delete newErrors[idx];
      setEarringErrors(newErrors);

      document.getElementById('nested' + idx).style.display = 'none';
    }
  }

  return (
    <>
      <div className="page-content">
        <Message title={messageAlert} type={typeMessage} />
        <MetaTags>
          <title>Create Movement | SistemasW - Fazenda</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={props.t('Movements')} breadcrumbItem={props.t('Movements')} />
          <Form
            className="outer-repeater"
            id="formSubmit"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">{props.t('Create New Entry Movement')}</CardTitle>
                    <div data-repeater-list="outer-group" className="outer">
                      <div data-repeater-item className="outer">
                        <FormGroup>
                          <Row>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label htmlFor="collectName">{props.t('Collects')}</Label>
                                <Input
                                  id="collectName"
                                  name="collectName"
                                  type="text"
                                  className="form-control"
                                  placeholder={props.t('Entre com o nome da coleta')}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.collectName || ''}
                                  invalid={
                                    validation.touched.collectName && validation.errors.collectName
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.collectName && validation.errors.collectName ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.collectName}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>

                            <Col lg="3">
                              <div className="mb-3">
                                <Label>{props.t('Farm')}</Label>
                                <Select
                                  id="farmId"
                                  name="farmId"
                                  value={selectedFarm}
                                  onChange={handleSelectFarm}
                                  options={optionGroupFarm}
                                />
                              </div>
                            </Col>

                            <Col lg="3">
                              <div className="mb-3">
                                <Label>{props.t('Type')}</Label>
                                <Select
                                  id="movementTypeId"
                                  name="movementTypeId"
                                  value={selectedMovementType}
                                  onChange={handleSelectMovementType}
                                  options={optionGroupMovementType}
                                />
                              </div>
                            </Col>

                            <Col lg="3">
                              <div className="mb-3">
                                <Label>{props.t('Data')}</Label>
                                <ReactInputMask
                                  mask="99/99/9999"
                                  value={validation.values.movementDate || ''}
                                  onChange={handleDateChange}
                                  onBlur={validation.handleBlur}
                                  className="form-control">
                                  {(inputProps) => (
                                    <Input
                                      {...inputProps}
                                      type="text"
                                      id="movementDate"
                                      name="movementDate"
                                      placeholder="dd/mm/yyyy"
                                      invalid={
                                        validation.touched.movementDate &&
                                        validation.errors.movementDate
                                          ? true
                                          : false
                                      }
                                    />
                                  )}
                                </ReactInputMask>
                                {validation.touched.movementDate &&
                                validation.errors.movementDate ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.movementDate}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>

                            <Col lg="3">
                              <div className="mb-3">
                                <Label htmlFor="amount">{props.t('Amount')}</Label>
                                <Input
                                  id="amount"
                                  name="amount"
                                  className="form-control"
                                  placeholder={props.t('Enter the total amount')}
                                  onChange={handleNumberChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.amount || ''}
                                  invalid={
                                    validation.touched.amount && validation.errors.amount
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.amount && validation.errors.amount ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.amount}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>

                            <Col lg="3">
                              <div className="mb-3">
                                <Label htmlFor="freight">{props.t('Freight')}</Label>
                                <Input
                                  id="freight"
                                  name="freight"
                                  className="form-control"
                                  placeholder={props.t('Enter freight the collect')}
                                  onChange={handleNumberChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.freight || ''}
                                  invalid={
                                    validation.touched.freight && validation.errors.freight
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.freight && validation.errors.freight ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.freight}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>

                            <Col lg="3">
                              <div className="mb-3">
                                <Label htmlFor="other_values">{props.t('Other values')}</Label>
                                <Input
                                  id="other_values"
                                  name="other_values"
                                  className="form-control"
                                  placeholder={props.t('Enter other values the collect')}
                                  onChange={handleNumberChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.other_values || ''}
                                  invalid={
                                    validation.touched.other_values &&
                                    validation.errors.other_values
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.other_values &&
                                validation.errors.other_values ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.other_values}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>

                            <Col lg="3">
                              <div className="mb-3">
                                <Label htmlFor="arroba_price">{props.t('Arroba price')}</Label>
                                <Input
                                  id="arroba_price"
                                  name="arroba_price"
                                  className="form-control"
                                  placeholder={props.t('Enter arroba price the collect')}
                                  onChange={handleNumberChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.arroba_price || ''}
                                  invalid={
                                    validation.touched.arroba_price &&
                                    validation.errors.arroba_price
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.arroba_price &&
                                validation.errors.arroba_price ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.arroba_price}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                        </FormGroup>
                      </div>
                    </div>

                    <CardTitle className="mb-4">{props.t('Add Animals')}</CardTitle>
                    <div className="inner-repeater mb-4">
                      <div className="inner form-group mb-0">
                        <div className="inner col-lg-12 ml-md-auto" id="repeater">
                          {animals.map((field, key) => (
                            <div
                              key={key}
                              id={'nested' + key}
                              className="mb-3 row align-items-center">
                              <FormGroup>
                                <Row>
                                  <Col lg="2">
                                    <div className="md-2">
                                      <Label>{props.t('Breed')}</Label>
                                      <Select
                                        defaultValue={field.breed}
                                        value={selectedBreed}
                                        onChange={(breed) => {
                                          handleSelectBreed();
                                          handleAddBreed(key, breed.value);
                                        }}
                                        options={optionGroupBreed}
                                        classNamePrefix="select2-selection"
                                      />
                                    </div>
                                  </Col>

                                  <Col lg="2">
                                    <div className="md-2">
                                      <Label>{props.t('Iron')}</Label>
                                      <Select
                                        value={selectedIron}
                                        onChange={(iron) => {
                                          handleSelectIron();
                                          handleAddIron(key, iron.value);
                                        }}
                                        options={optionGroupIron}
                                        classNamePrefix="select2-selection"
                                      />
                                    </div>
                                  </Col>

                                  <Col lg="2">
                                    <div className="md-2">
                                      <Label>{props.t('Earring')}</Label>
                                      <Input
                                        id={`earring${key}`}
                                        type="text"
                                        className={`inner form-control ${
                                          earringErrors[key] ? 'is-invalid' : ''
                                        }`}
                                        defaultValue={field.earring}
                                        onFocus={() => setIsLoading(true)}
                                        placeholder={props.t('Enter Earring')}
                                        onChange={(e) => handleAddEarring(key, e.target.value)}
                                        onBlur={(e) => validateEarring(key, e.target.value)}
                                      />
                                      {earringErrors[key] && (
                                        <FormFeedback type="invalid" style={{ display: 'block' }}>
                                          {earringErrors[key]}
                                        </FormFeedback>
                                      )}
                                    </div>
                                  </Col>

                                  <Col lg="1">
                                    <div className="md-2">
                                      <Label>{props.t('Weight')}</Label>
                                      <Input
                                        id="weight"
                                        name="weight"
                                        className="form-control"
                                        placeholder={props.t('Enter Weight')}
                                        value={validation.values[`${key}weight`]}
                                        onChange={(e) => handleNumberChangeAdd(key, e)}
                                        onBlur={validation.handleBlur}
                                        invalid={
                                          validation.touched[`${key}weight`] &&
                                          validation.errors[`${key}weight`]
                                            ? true
                                            : false
                                        }
                                      />
                                      {validation.touched[`${key}weight`] &&
                                      validation.errors[`${key}weight`] ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors[`${key}weight`]}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>

                                  <Col lg="2">
                                    <div className="md-2">
                                      <Label>{props.t('Earring Eletric')}</Label>

                                      <input
                                        type="text"
                                        className="inner form-control"
                                        defaultValue={field.earring_eletric}
                                        placeholder={props.t('Enter Earring Eletric')}
                                        onChange={() =>
                                          handleAddEarringEletric(key, event.target.value)
                                        }
                                      />
                                    </div>
                                  </Col>

                                  <Col lg="2" className="row">
                                    <div className="lg-9">
                                      <Label>{props.t('Obs')}.:</Label>
                                      <input
                                        type="text"
                                        className="inner form-control"
                                        defaultValue={field.note}
                                        placeholder={props.t('Enter note')}
                                        onChange={() => handleAddNote(key, event.target.value)}
                                      />
                                    </div>
                                  </Col>

                                  <Col lg="1" style={{ marginTop: 26 }}>
                                    <div className="md-2">
                                      <Button
                                        color="danger"
                                        className="inner form-control"
                                        onClick={() => {
                                          handleRemoveFields(key);
                                        }}
                                        block>
                                        <i className="mdi mdi-trash-can"></i>
                                      </Button>
                                    </div>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </div>
                          ))}

                          {isDropFile && (
                            <>
                              <Dropzone
                                onDrop={(acceptedFiles) => {
                                  handleAcceptedFiles(acceptedFiles);
                                }}>
                                {({ getRootProps, getInputProps }) => (
                                  <div className="dropzone">
                                    <div className="dz-message needsclick mt-2" {...getRootProps()}>
                                      <input {...getInputProps()} />
                                      <div className="mb-3">
                                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                                      </div>
                                      <h4>{props.t('Drop files here or click to upload')}.</h4>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                              <Row>
                                <div className="dropzone-previews mt-3" id="file-previews">
                                  {selectedFiles.map((f, i) => {
                                    return (
                                      <Card
                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                        key={i + '-file'}>
                                        <Row>
                                          <div className="p-2">
                                            <Row className="align-items-center">
                                              <Col style={{ marginLeft: 20 }}>
                                                <Link
                                                  to="#"
                                                  className="text-muted font-weight-bold">
                                                  {f.name}
                                                </Link>
                                                <p className="mb-0">
                                                  <strong>{f.formattedSize}</strong>
                                                </p>
                                              </Col>
                                            </Row>
                                          </div>
                                        </Row>
                                      </Card>
                                    );
                                  })}
                                </div>
                                {selectedFiles.length > 0 && (
                                  <Col md="2" style={{ marginTop: 10, marginBottom: 25 }}>
                                    <div className="md-4">
                                      <Button
                                        color="danger"
                                        className="inner form-control"
                                        onClick={() => {
                                          setselectedFiles([]);
                                          setIsDropFile(false);
                                        }}
                                        block>
                                        <i className="mdi mdi-trash-can"></i>
                                      </Button>
                                    </div>
                                  </Col>
                                )}
                              </Row>
                            </>
                          )}

                          <Row className="justify-content-start">
                            <Col lg="10">
                              <Button
                                color="success"
                                className="inner"
                                disabled={isLoading}
                                isLoading={isLoading}
                                onClick={() => {
                                  isDropFile ? null : handleAddFields();
                                }}>
                                {props.t('Add Animals')}
                              </Button>

                              <Button
                                color="success"
                                className="inner"
                                disabled={isLoading}
                                isLoading={isLoading}
                                style={{ marginLeft: 10 }}
                                onClick={() => {
                                  animals.length > 0 ? null : setIsDropFile((v) => !v);
                                }}>
                                {props.t('Import animals')}
                              </Button>
                              {isDropFile > 0 && (
                                <Button
                                  color="primary"
                                  className="inner"
                                  disabled={isLoading}
                                  isLoading={isLoading}
                                  style={{ marginLeft: 10 }}
                                  onClick={() =>
                                    window.open(
                                      `${process.env.REACT_APP_SYSTEM_URL.replace(
                                        '/api/v1',
                                        ''
                                      )}/import/iron_import_e.csv`
                                    )
                                  }>
                                  {props.t('Download Sheet')}
                                </Button>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">{props.t('Photo gallery')}</CardTitle>
                    <FormGroup>
                      <Row form>
                        <MediaLibrary
                          resource={'collect'}
                          isSendForCreate
                          isEditableItemName
                          onChange={handleChangeFilesMediaLibrary}
                        />
                      </Row>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col md="6">
                        <div className="mt-4" style={{ textAlign: 'right' }}>
                          <button type="submit" form="formSubmit" className="btn btn-primary ">
                            {isDropFile ? 'Importar Arquivos' : 'Salvar'}
                          </button>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="text-left mt-4">
                          <button
                            className="btn btn-danger"
                            onClick={() => history.push('/movements')}>
                            {'Cancel'}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  );
};

MovementCreate.propTypes = {
  breeds: PropTypes.array,
  irons: PropTypes.array,
  farms: PropTypes.array,
  onGetBreeds: PropTypes.func,
  onGetIrons: PropTypes.func,
  onGetFarms: PropTypes.func,
  addNewMovement: PropTypes.func
};

export default withTranslation()(MovementCreate);
