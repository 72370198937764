import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Button, Card, CardBody, Col, Input, Row } from 'reactstrap';

import Select from 'react-select';
import { useFarmsStore } from 'zustand/farms/farms-store';
import { getAllByTestId } from '@testing-library/react';

const FilterOnDashboard = ({ onFilter }) => {
  const [date, setDate] = useState({});
  const { allFarms: farmsWhenStore,allIrons: ironsWhenStore,allBreeds: breedsWhenStore,getAllBreed,getAllIron,getAllFarms
   } = useFarmsStore();
  const [selectedOption, setSelectedOption] = useState(farmsWhenStore);
  const [selectedIronOption, setSelectedIronOption] = useState(ironsWhenStore);
  const [selectedBreedOption, setSelectedBreedOption] = useState(breedsWhenStore);

  useEffect(() => {
    getAllFarms();
    getAllBreed();
    getAllIron();
  }, []);

  const options = useMemo(() => {
    return farmsWhenStore?.map((farm) => ({ value: farm.id, label: farm.name })) || [];
  }, [farmsWhenStore]);

  const optionsIron = useMemo(() => {
    return ironsWhenStore?.map((iron) => ({ value: iron.id, label: iron.name })) || [];
  }, [ironsWhenStore]);

  
  const optionsBreed = useMemo(() => {
    return breedsWhenStore?.map((breed) => ({ value: breed.id, label: breed.name })) || [];
  }, [breedsWhenStore]);


  return (
    <Row>
      <Card style={{ margin: 0, padding: 0 }}>
        <CardBody style={{ margin: 0, paddingRight: '7px', paddingLeft: '7px' }}>
          <Row>
            <Col md={3}>
              <strong>De</strong>
              <Input
                onChange={(event) => setDate({ ...date, initial_date: event.target.value })}
                type="date"
                name="initial_date"
                id="initial_date"
                placeholder="De"
                value={date.initial_date}
              />
            </Col>
            <Col md={3}>
              <strong>Ate</strong>
              <Input
                onChange={(event) => setDate({ ...date, final_date: event.target.value })}
                type="date"
                name="final_date"
                id="final_date"
                placeholder="Ate"
                value={date.final_date}
              />
            </Col>
            <Col>
              <strong>Select Farms</strong>
              <Select
                isMulti
                defaultValue={options}
                onChange={setSelectedOption}
                options={options}
              />
            </Col>
            <Col>
              <strong>Select Breed</strong>
              <Select
                isMulti
                defaultValue={optionsBreed}
                onChange={setSelectedBreedOption}
                options={optionsBreed}
              />
            </Col>
            <Col>
              <strong>Select Iron</strong>
              <Select
                isMulti
                defaultValue={optionsIron}
                onChange={setSelectedIronOption}
                options={optionsIron}
              />
            </Col>
          </Row>
          <Row>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
                gap: '5px',
                top: '18px'
              }}>
              <Button
                color="primary"
                onClick={() => {
                  onFilter({
                    initial_date: date.initial_date,
                    final_date: date.final_date,
                    farm_ids: selectedOption.map((option) => option.id ?? option.value),
                    breed_ids: selectedBreedOption.map((option) => option.id ?? option.value),
                    iron_ids: selectedIronOption.map((option) => option.id ?? option.value),
                  });
                }}>
                Filtrar
              </Button>
              <Button color="danger">Limpar</Button>
            </div>
          </Row>
        </CardBody>
      </Card>
    </Row>
  );
};

export default FilterOnDashboard;
