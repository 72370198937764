import React, { useState, useEffect } from 'react';
import { Row, Button, FormGroup, Card, CardTitle, Input, Col } from 'reactstrap';
import { Formik, Field, Form } from 'formik';

import 'react-datepicker/dist/react-datepicker.css';
import { withTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { setSyntheticFilterValues, setFilteredSynthetic } from 'store/actions';


import {
  getSyntheticGains as onGetSyntheticGains,
} from 'store/reports/gains/actions'

const SearchSynthetic = (props) => {

  const [collectItensState, setCollectItensState] = useState([]);
  const [collectFilter,setCollectFilter] = useState(false);


  const dispatch = useDispatch();
  let { syntheticGains } = useSelector((state) => ({
    syntheticGains: state.ReportGains.syntheticGains
  }));


  useEffect(() => {
    if (syntheticGains.length > 0) {
      let collectItens = [
        {
          value : null,
          name : "Selecione"
        }
        
      ];
      syntheticGains.map((item) => {
        if (collectItens.filter((collect) => collect.value == item.collect_id).length == 0) {
          collectItens.push({ value: item.collect_id, name: item.collect_name });
        }
      });
      setCollectItensState(collectItens.sort());
    }
  }, []);

  
  const clearForm = () => {
    console.log('limpando formulario ');
    if(collectFilter){
      dispatch(onGetSyntheticGains({}));
      setCollectFilter(false)

    }

    dispatch(setFilteredSynthetic(false));
  };



  const handleSubmit = (values) => {
      if(values.collect_id2 && values.collect_id3 && values.collect_id2 !== 'Selecione' && values.collect_id3 !== 'Selecione'){
        dispatch(onGetSyntheticGains({
          collect_id1 : values.collect_id2,
          collect_id2 : values.collect_id3
        }))
        setCollectFilter(true)
      }
      else{
        if(collectFilter){
          dispatch(onGetSyntheticGains({}));
          setCollectFilter(false)
        }
      }
      console.log('values filter',values)
      dispatch(setSyntheticFilterValues(values))
      dispatch(setFilteredSynthetic(true))
     //
    }
  

  return (
    <Formik
      initialValues={{
        collect_id: '',
        collect_id2: null,
        collect_id3: null,
        collect_idQuantity: null,

        measurement_condition: '',
        measurement_conditionQuantity: null,

        difference_days: '>',
        difference_daysQuantity: null,

        avg_month: '>',
        avg_monthQuantity: null,

        gains_kg: '',
        gains_kgQuantity: null,

        final_weight: '',
        final_weightQuantity: null,

        qtd_total_animals: '',
        qtd_total_animalsQuantity: null,

        qtd_total_animals_com_brinco: '',
        qtd_total_animals_com_brincoQuantity: null,

        qtd_total_animals_sem_brinco: '',
        qtd_total_animals_sem_brincoQuantity: null,

        start_end_weight_arroba: '',
        start_end_weight_arrobaQuantity: null,

        start_weight_arroba: '',
        start_weight_arrobaQuantity: null,

        start_weight: '',
        start_weightQuantity: null,

        final_weight_arroba: '',
        final_weight_arrobaQuantity: null,

        gains_day_arroba: '',
        gains_day_arrobaQuantity: null,

        gains_month_arroba: '',
        gains_month_arrobaQuantity: null,

        gains_year_arroba: '',
        gains_year_arrobaQuantity: null,

        initialDate: '',
        initialDateQuantity: null,

        initialDateEnd: '',
        initialDateEndQuantity: null,

        de: null,
        ate: null
      }}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values);
      }}>
      {({ isSubmitting }) => (
        <Form inline>
          <Row form>
            <Col md={4}>
              <Card style={{ margin: 0 }}>
                <CardTitle>Coleta 1</CardTitle>
                <FormGroup>
                  <Field
                    as={Input}
                    type="select"
                    name="collect_id2"
                    id="collect_id2"
                    placeholder="Nome da Coleta">
                    {collectItensState.map((op) => (
                      <option key={op.value} value={op.value}>
                        {op.name}
                      </option>
                    ))}
                  </Field>
                </FormGroup>
              </Card>
            </Col>
            <Col md={4}>
              <Card style={{ margin: 0 }}>
                <CardTitle>Coleta 2</CardTitle>
                <FormGroup>
                  <Field
                    as={Input}
                    type="select"
                    name="collect_id3"
                    id="collect_id3"
                    placeholder="Nome da Coleta">
                    {collectItensState.map((op) => (
                      <option key={`d${op.value}`} value={op.value}>
                        {op.name}
                      </option>
                    ))}
                  </Field>
                </FormGroup>
              </Card>
            </Col>
          </Row>
          <Row form>
            <Col md={2}>
              <Card style={{ margin: 0 }}>
                <CardTitle>Id</CardTitle>
                <FormGroup>
                  <Field
                    type="id"
                    name="collect_id"
                    id="collect_id"
                    placeholder="Id"
                    className="form-control"
                  />
                </FormGroup>
              </Card>
            </Col>
            <Col>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t('Total measurement days')}</CardTitle>
                <FormGroup>
                  <Row>
                    <Col>
                      <Field
                        as="select"
                        name="difference_days"
                        id="difference_days"
                        className="form-control">
                        {['>', '<', '>=', '<='].map((op) => (
                          <option key={op} value={op}>
                            {op}
                          </option>
                        ))}
                      </Field>
                    </Col>
                    <Col>
                      <Field
                        type="number"
                        name="difference_daysQuantity"
                        id="difference_daysQuantity"
                        placeholder="Quantity"
                        className="form-control"
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>
            <Col md={6}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t('Average measurement months')}</CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={6}>
                      <Field as="select" name="avg_month" id="avg_month" className="form-control">
                        {['>', '<', '>=', '<='].map((op) => (
                          <option key={op} value={op}>
                            {op}
                          </option>
                        ))}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field
                        type="number"
                        name="avg_monthQuantity"
                        id="avg_monthQuantity"
                        placeholder="Quantity"
                        className="form-control"
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>

            <Col md={6}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t('Measurement Condition')}</CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={6}>
                      <Field
                        as="select"
                        name="measurement_condition"
                        id="measurement_condition"
                        className="form-control">
                        {['>', '<', '>=', '<='].map((op) => (
                          <option key={op} value={op}>
                            {op}
                          </option>
                        ))}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field
                        type="number"
                        name="measurement_conditionQuantity"
                        id="measurement_conditionQuantity"
                        placeholder="Quantity"
                        className="form-control"
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>

            <Col md={6}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t('Average measurement Condition')}</CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={6}>
                      <Field as="select" name="avg_month" id="avg_month" className="form-control">
                        {['>', '<', '>=', '<='].map((op) => (
                          <option key={op} value={op}>
                            {op}
                          </option>
                        ))}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field
                        type="number"
                        name="avg_monthQuantity"
                        id="avg_monthQuantity"
                        placeholder="Quantity"
                        className="form-control"
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>

            <Col md={6}>
              <Card style={{ margin: 0 }}>
                <CardTitle>
                  {props.t('Difference final total weight minus initial total weight')}
                </CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={6}>
                      <Field as="select" name="gains_kg" id="gains_kg" className="form-control">
                        {['>', '<', '>=', '<='].map((op) => (
                          <option key={op} value={op}>
                            {op}
                          </option>
                        ))}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field
                        type="number"
                        name="gains_kgQuantity"
                        id="gains_kgQuantity"
                        placeholder="Quantity"
                        className="form-control"
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>

            <Col md={6}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t('Total number of cattle')}</CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={6}>
                      <Field
                        as="select"
                        name="qtd_total_animals"
                        id="qtd_total_animals"
                        className="form-control">
                        {['>', '<', '>=', '<='].map((op) => (
                          <option key={op} value={op}>
                            {op}
                          </option>
                        ))}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field
                        type="number"
                        name="qtd_total_animalsQuantity"
                        id="qtd_total_animalsQuantity"
                        placeholder="Quantity"
                        className="form-control"
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>

            <Col md={6}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t('Number of cattle with earring')}</CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={6}>
                      <Field
                        as="select"
                        name="qtd_total_animals_com_brinco"
                        id="qtd_total_animals_com_brinco"
                        className="form-control">
                        {['>', '<', '>=', '<='].map((op) => (
                          <option key={op} value={op}>
                            {op}
                          </option>
                        ))}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field
                        type="number"
                        name="qtd_total_animals_com_brincoQuantity"
                        id="qtd_total_animals_com_brincoQuantity"
                        placeholder="Quantity"
                        className="form-control"
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>

            <Col md={6}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t('Number of cattle without earring')}</CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={6}>
                      <Field
                        as="select"
                        name="qtd_total_animals_sem_brinco"
                        id="qtd_total_animals_sem_brinco"
                        className="form-control">
                        {['>', '<', '>=', '<='].map((op) => (
                          <option key={op} value={op}>
                            {op}
                          </option>
                        ))}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field
                        type="number"
                        name="qtd_total_animals_sem_brincoQuantity"
                        id="qtd_total_animals_sem_brincoQuantity"
                        placeholder="Quantity"
                        className="form-control"
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>

            <Col md={6}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t('Total Weight End With Earring')}</CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={6}>
                      <Field
                        as="select"
                        name="start_end_weight_arroba"
                        id="start_end_weight_arroba"
                        className="form-control">
                        {['>', '<', '>=', '<='].map((op) => (
                          <option key={op} value={op}>
                            {op}
                          </option>
                        ))}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field
                        type="number"
                        name="start_end_weight_arrobaQuantity"
                        id="start_end_weight_arrobaQuantity"
                        placeholder="Quantity"
                        className="form-control"
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>

            <Col md={6}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t('Total Weight Initial WithE arring')}</CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={6}>
                      <Field
                        as="select"
                        name="start_weight_arroba"
                        id="start_weight_arroba"
                        className="form-control">
                        {['>', '<', '>=', '<='].map((op) => (
                          <option key={op} value={op}>
                            {op}
                          </option>
                        ))}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field
                        type="number"
                        name="start_weight_arrobaQuantity"
                        id="start_weight_arrobaQuantity"
                        placeholder="Quantity"
                        className="form-control"
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>

            <Col md={6}>
              <Card style={{ margin: 0 }}>
                <CardTitle>
                  {props.t('Total Weight GainFinal Minus Initial With Earring')}
                </CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={6}>
                      <Field
                        as="select"
                        name="final_weight_arroba"
                        id="final_weight_arroba"
                        className="form-control">
                        {['>', '<', '>=', '<='].map((op) => (
                          <option key={op} value={op}>
                            {op}
                          </option>
                        ))}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field
                        type="number"
                        name="final_weight_arrobaQuantity"
                        id="final_weight_arrobaQuantity"
                        placeholder="Quantity"
                        className="form-control"
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>

            <Col md={6}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t('Average Earnings Per Livestock InPer Day')}</CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={6}>
                      <Field
                        as="select"
                        name="gains_day_arroba"
                        id="gains_day_arroba"
                        className="form-control">
                        {['>', '<', '>=', '<='].map((op) => (
                          <option key={op} value={op}>
                            {op}
                          </option>
                        ))}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field
                        type="number"
                        name="gains_day_arrobaQuantity"
                        id="gains_day_arrobaQuantity"
                        placeholder="Quantity"
                        className="form-control"
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>

            <Col md={6}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t('Average Earnings Per Livestock InPer Year')}</CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={6}>
                      <Field
                        as="select"
                        name="gains_year_arroba"
                        id="gains_year_arroba"
                        className="form-control">
                        {['>', '<', '>=', '<='].map((op) => (
                          <option key={op} value={op}>
                            {op}
                          </option>
                        ))}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field
                        type="number"
                        name="gains_year_arrobaQuantity"
                        id="gains_year_arrobaQuantity"
                        placeholder="Quantity"
                        className="form-control"
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>
            <Col md={3}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t('From')} </CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={12}>
                      <Field as={Input} type="date" name="de" id="de" placeholder="De" />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>
            <Col md={3}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t('To')} </CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={12}>
                      <Field as={Input} type="date" name="ate" id="ate" placeholder="Ate" />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>

            {/* Replicar este bloco para cada campo */}
          </Row>
          <Row>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                gap: '10px',
                marginTop: '3rem',
                left: '40%'
              }}>
              <Button color="primary" type="submit">
                {props.t('Search filter')}
              </Button>
              <Button color="danger" type="reset" onClick={() => clearForm()}>
                {props.t('Clear')}
              </Button>
            </div>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default withTranslation()(SearchSynthetic);
