import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { Row, Button, FormGroup, Input, Col, Card, CardTitle } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { setFilterAnalyticGains, setFilteredAnalytic } from 'store/actions';

function compare(a, op, b) {
  console.log('teste', op);
  switch (op) {
    case '>':
      if (b > a) {
        return true;
      } else {
        return false;
      }
    case '<':
      if (b < a) {
        return true;
      } else {
        return false;
      }
    case '<=':
      if (b <= a) {
        return true;
      } else {
        return false;
      }
    case '>=':
      if (b >= a) {
        return true;
      } else {
        return false;
      }
    default:
      false;
  }
}

const SearchAnalytic = (props) => {
  const dispatch = useDispatch();
  const { analyticGains } = useSelector((state) => ({
    analyticGains: state.ReportGains.analyticGains
  }));
  const [collectItensState, setCollectItensState] = useState([]);
  const { analyticFilteredGains } = useSelector((state) => ({
    analyticFilteredGains: state.ReportGains.analyticFilteredGains
  }));

  useEffect(() => {
    if (analyticGains.itens && analyticGains.itens.length > 0) {
      let collectItens = [];
      analyticGains.itens.map((item) => {
        if (!collectItens.includes(item.collect2_name)) {
          collectItens.push(item.collect2_name);
        }
      });
      setCollectItensState(collectItens.sort());
    }
  }, [analyticGains]);

  const clearForm = () => {
    console.log('limpando formulario ');
    dispatch(setFilteredAnalytic(false));
  };

  const handleSubmit = (values) => {
    if (analyticGains.itens && analyticGains.itens.length > 0) {
      let analyticGainsFiltered = analyticGains.itens;

      if (values.animal_id) {
        analyticGainsFiltered = analyticGainsFiltered.filter(
          (filter) => filter.id == parseInt(values.animal_id)
        );
      }

      if (values.earringQuantity !== null && values.earringQuantity !== '') {
        analyticGainsFiltered = analyticGainsFiltered.filter((filter) =>
          compare(parseInt(values.earringQuantity), values.animal_earring, parseInt(filter.earring))
        );
      }

      if (values.final_weightQuantity !== null && values.final_weightQuantity !== '') {
        analyticGainsFiltered = analyticGainsFiltered.filter((filter) =>
          compare(
            parseInt(values.final_weightQuantity),
            values.final_weight,
            parseInt(filter.final_weight)
          )
        );
      }
      if (values.start_weightQuantity !== null && values.start_weightQuantity !== '') {
        analyticGainsFiltered = analyticGainsFiltered.filter((filter) =>
          compare(
            parseInt(values.start_weightQuantity),
            values.start_weight,
            parseInt(filter.start_weight)
          )
        );
      }
      if (values.difference_daysQuantity !== null && values.difference_daysQuantity !== '') {
        analyticGainsFiltered = analyticGainsFiltered.filter((filter) =>
          compare(
            parseInt(values.difference_daysQuantity),
            values.difference_days,
            parseInt(filter.difference_days)
          )
        );
      }
      if (values.gains_kgQuantity !== null && values.gains_kgQuantity !== '') {
        analyticGainsFiltered = analyticGainsFiltered.filter((filter) =>
          compare(
            parseInt(values.gains_kgQuantity),
            values.gains_kg,
            parseInt(filter.gains_year_kg)
          )
        );
      }
      if (values.gains_year_arrobaQuantity !== null && values.gains_year_arrobaQuantity !== '') {
        analyticGainsFiltered = analyticGainsFiltered.filter((filter) =>
          compare(
            parseInt(values.gains_year_arrobaQuantity),
            values.gains_year_arroba,
            parseInt(filter.gains_year_arroba)
          )
        );
      }

      if (values.collect_id) {
        analyticGainsFiltered = analyticGainsFiltered.filter((filter) =>
          filter.collect2_name.toLowerCase().includes(values.collect_id.toLowerCase())
        );
      }

      if (values.obs) {
        analyticGainsFiltered = analyticGainsFiltered.filter((filter) =>
          filter.obs.toLowerCase().includes(values.obs.toLowerCase())
        );
      }

      if (values.de && values.ate) {
        let de = new Date(values.de)
        let ate = new Date(values.ate)
        analyticGainsFiltered = analyticGainsFiltered.filter(
          (filter) => {
            let final_date = new Date(filter.final_date);
            return final_date >= de && final_date <= ate ? true : false
          }
        );
      }

      if (values.de_start_date && values.ate_start_date) {
        console.log(values.de_start_date);
        let de_start_date = new Date(values.de_start_date)
        let ate_start_date = new Date(values.ate_start_date)
        console.log(de_start_date)

        analyticGainsFiltered = analyticGainsFiltered.filter(
          (filter) => {
            let start_date = new Date(filter.start_date)
            console.log(filter)
            console.log(start_date)
            return start_date >= de_start_date && start_date <= ate_start_date ? true : false
          }
        );
      }

      dispatch(setFilteredAnalytic(false));

      dispatch(setFilterAnalyticGains(analyticGainsFiltered));
      console.log(analyticGainsFiltered);
    }
  };

  return (
    <Formik
      initialValues={{
        collect_id: '',
        animal_earring: '>',
        earringQuantity: null,
        final_weight: '>',
        final_weightQuantity: null,
        start_weight: '>',
        start_weightQuantity: null,
        difference_days: '>',
        difference_daysQuantity: null,
        gains_kg: '>',
        gains_kgQuantity: null,
        gains_year_arroba: '>',
        gains_year_arrobaQuantity: null,
        obs: '',
        de: null,
        ate: null,
        de_start_date: null,
        ate_start_date: null
      }}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values);
      }}>
      {({ isSubmitting }) => (
        <Form inline>
          <Row form>
            <Col md={4}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t('Collect Name')}</CardTitle>
                <FormGroup>
                  <Field
                    as={Input}
                    type="select"
                    name="collect_id"
                    id="collect_id"
                    placeholder="Nome da Coleta">
                    {collectItensState.map((op) => (
                      <option key={op} value={op}>
                        {op}
                      </option>
                    ))}
                  </Field>
                </FormGroup>
              </Card>
            </Col>
          </Row>
          <Row form>
            <Col md={2}>
              <Card style={{ margin: 0 }}>
                <CardTitle>Id</CardTitle>
                <FormGroup>
                  <Field as={Input} type="id" name="animal_id" id="animal_id" placeholder="id" />
                </FormGroup>
              </Card>
            </Col>

            <Col md={4}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t('Earring')}</CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={6}>
                      <Field
                        as={Input}
                        type="select"
                        name="animal_earring"
                        id="animal_earring"
                        placeholder="Sinal">
                        {['>', '<', '>=', '<='].map((op) => (
                          <option key={op} value={op}>
                            {op}
                          </option>
                        ))}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field
                        as={Input}
                        type="number"
                        name="earringQuantity"
                        id="earringQuantity"
                        placeholder="Quantity"
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>

            <Col md={6}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t('Final weight')}</CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={6}>
                      <Field
                        as={Input}
                        type="select"
                        name="final_weight"
                        id="final_weight"
                        placeholder="Sinal">
                        {['>', '<', '>=', '<='].map((op) => (
                          <option key={op} value={op}>
                            {op}
                          </option>
                        ))}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field
                        as={Input}
                        type="number"
                        name="final_weightQuantity"
                        id="final_weightQuantity"
                        placeholder="Quantity"
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>

            <Col md={6}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t('Start weight')}</CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={6}>
                      <Field
                        as={Input}
                        type="select"
                        name="start_weight"
                        id="start_weight"
                        placeholder="Sinal">
                        {['>', '<', '>=', '<='].map((op) => (
                          <option key={op} value={op}>
                            {op}
                          </option>
                        ))}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field
                        as={Input}
                        type="number"
                        name="start_weightQuantity"
                        id="start_weightQuantity"
                        placeholder="Quantity"
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>

            <Col md={6}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t('Difference days')}</CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={6}>
                      <Field
                        as={Input}
                        type="select"
                        name="difference_days"
                        id="difference_days"
                        placeholder="Sinal">
                        {['>', '<', '>=', '<='].map((op) => (
                          <option key={op} value={op}>
                            {op}
                          </option>
                        ))}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field
                        as={Input}
                        type="number"
                        name="difference_daysQuantity"
                        id="difference_daysQuantity"
                        placeholder="Quantity"
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>

            <Col md={6}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t('Gains kg')}</CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={6}>
                      <Field
                        as={Input}
                        type="select"
                        name="gains_kg"
                        id="gains_kg"
                        placeholder="Sinal">
                        {['>', '<', '>=', '<='].map((op) => (
                          <option key={op} value={op}>
                            {op}
                          </option>
                        ))}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field
                        as={Input}
                        type="number"
                        name="gains_kgQuantity"
                        id="gains_kgQuantity"
                        placeholder="Quantity"
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>

            <Col md={6}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t('Gains year arroba')}</CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={6}>
                      <Field
                        as={Input}
                        type="select"
                        name="gains_year_arroba"
                        id="gains_year_arroba"
                        placeholder="Sinal">
                        {['>', '<', '>=', '<='].map((op) => (
                          <option key={op} value={op}>
                            {op}
                          </option>
                        ))}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field
                        as={Input}
                        type="number"
                        name="gains_year_arrobaQuantity"
                        id="gains_year_arrobaQuantity"
                        placeholder="Quantity"
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>
            <Col md={3}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t('From Start Date')} </CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={12}>
                      <Field
                        as={Input}
                        type="date"
                        name="de_start_date"
                        id="de_start_date"
                        placeholder="De data de inicio"
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>
            <Col md={3}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t('To Start Date')} </CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={12}>
                      <Field
                        as={Input}
                        type="date"
                        name="ate_start_date"
                        id="ate_start_date"
                        placeholder="Ate data de inicio"
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>
            <Col md={3}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t('From')} </CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={12}>
                      <Field as={Input} type="date" name="de" id="de" placeholder="De" />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>
            <Col md={3}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t('To')} </CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={12}>
                      <Field as={Input} type="date" name="ate" id="ate" placeholder="Ate" />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>

            <Col md={6}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t('Note')}</CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={12}>
                      <Input type="text" id="obs" name="obs" placeholder="Obs" />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>
          </Row>

          <Row>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                gap: '10px',
                marginTop: '3rem',
                left: '40%'
              }}>
              <Button color="primary" type="submit">
                {props.t('Search filter')}
              </Button>
              <Button color="danger" type="reset" onClick={() => clearForm()}>
                {props.t('Clear')}
              </Button>
            </div>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default withTranslation()(SearchAnalytic);
