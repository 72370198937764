import create from 'zustand';
import axiosApi from 'helpers/api_helper';
import { COLLECTS_CHECK_EARRING } from 'helpers/url_helper';

export class CollectService {
  async checkEarring(earring) {
    const response = await axiosApi.get(`${COLLECTS_CHECK_EARRING}/${earring}`);
    return response.data.data ?? [];
  }
}

export const farmsService = new CollectService();

export const useCollectsStore = create((set) => ({
  isLoading: false,

  collects: [],

  setIsLoading: (isLoading) => set({ isLoading }),

  setCollects: (collects) => set({ collects }),

  addCollect: (collect) => set((state) => ({ collects: [...state.collects, collect] })),

  updateCollect: (collect) =>
    set((state) => ({
      collects: state.collects.map((c) => (c.id === collect.id ? collect : c))
    })),

  removeCollect: (collectId) =>
    set((state) => ({
      collects: state.collects.filter((c) => c.id !== collectId)
    })),

  resetCollects: () => set({ collects: [] })
}));

export default farmsService;
