import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { NavItem, Table } from 'reactstrap';
import useStockStore from 'zustand/stocks/stocks-store';
import ModalInventory from './ModalInventory';
import DropFilterInventory from './Filters/DropFilterInventory';
import { withTranslation } from 'react-i18next';
import paginationFactory, { SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';

const styles = {
  tableContainer: {
    whiteSpace: 'nowrap',
    background: '#fff'
  },
  celula: {
    color: '#fff'
  },
  columnHeader: {
    background: 'red'
  },
  stock: {
    whiteSpace: 'nowrap',
    backgroundColor: '#c4c4c4',
    fontWeight: 'bolder'
  }
};

const InvetoryTable = (props) => {
  const { stock, getStocks, getMovementsByCollectId, isLoading } = useStockStore();
  const [isOpenInvetoryModal, setIsOpenInvetoryModal] = useState(false);
  const [dataForOpenModal, setDataForOpenModal] = useState({ movement_date: new Date() });

  const movementsStocks = useMemo(() => {
    const stocksArray = [];
    const movementsArray = [];
    const movementDataArray = [];
    const totalEstoqueOfFarm = [];
    const lastMovimentStocks = [];

    // Sort movements by ID ascending
    const sortedMovements = [...stock.movements].sort((a, b) => a.id - b.id);

    sortedMovements.forEach((movement, movementIndex) => {
      const stocksInArray = [];
      const movementInArray = [];

      if (!movement.id in lastMovimentStocks) {
        lastMovimentStocks[movement.id] = 0;
      }

      movementDataArray[movementIndex] = movement;
      stock.farms.forEach((farm) => {
        if (totalEstoqueOfFarm[farm.id] === undefined) {
          console.log('totalEstoqueOfFarmInFarmNotexists', farm.id);
          totalEstoqueOfFarm[farm.id] = 0;
        }

        if (farm.name === movement.farm_name) {
          movementInArray.push(Number(movement.quantidade));

          if (['E', 'TE'].includes(movement.operation)) {
            totalEstoqueOfFarm[farm.id] = totalEstoqueOfFarm[farm.id] + Number(movement.quantidade);
          } else {
            totalEstoqueOfFarm[farm.id] = totalEstoqueOfFarm[farm.id] - Number(movement.quantidade);
          }

          stocksInArray.push(totalEstoqueOfFarm[farm.id]);

          const lastIndexMovementStock = totalEstoqueOfFarm.length - 1;
          const ifExistsLastIndexMovementStockInArray = lastIndexMovementStock in lastMovimentStocks;

          if (ifExistsLastIndexMovementStockInArray) {
            lastIndexMovementStock[movement.id] = lastIndexMovementStock[movement.id] + Number(movement.quantidade);
          }
        } else {
          movementInArray.push(0);
          stocksInArray.push(totalEstoqueOfFarm[farm.id]);
        }
      });

      movementsArray.push(movementInArray);
      stocksArray.push(stocksInArray);
    });

    return {
      stocksArray,
      movementsArray,
      movementDataArray,
      totalEstoqueOfFarm,
      lastMovimentStocks
    };
  }, [stock]);

  const tryStocks = useCallback(async () => {
    getStocks().then((data) => console.log({ data }));
  }, [getStocks]);

  useEffect(() => {
    tryStocks();
  }, [tryStocks]);

  const toggleModal = () => {
    setIsOpenInvetoryModal(!isOpenInvetoryModal);
  };

  const handleOpenModalInvetory = (collect_id, farm_id) => {
    const movement = stock.movements.find((m) => m.collect_id === collect_id && m.farm_id === farm_id);
    setDataForOpenModal(movement);
    getMovementsByCollectId(collect_id, farm_id);
    toggleModal();
  };

  const handleFilter = (data) => {
    getStocks({ ...data });
  };

  const paginationOptions = {
    custom: true,
    totalSize: stock.movements.length,
    sizePerPage: 20,
    sizePerPageList: [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '20', value: 20 },
      { text: 'All', value: stock.movements.length }
    ]
  };

  return (
    <div className="table-responsive" style={styles.tableContainer}>
      <DropFilterInventory onFilter={handleFilter} />

      <ul className="nav nav-tabs nav-tabs-custom" style={{ zIndex: 1 }}>
        <NavItem style={{ marginRight: '10px' }}>
          {/* <div className="text-sm-end">
            <SizePerPageDropdownStandalone
              {...paginationOptions}
            />
          </div> */}
        </NavItem>
      </ul>

      <Table striped bordered responsive hover size="sm">
        <thead style={styles.columnHeader}>
          <tr className="table-secondary">
            <th>{props.t("id")}</th>
            <th>{props.t("description")}</th>
            {stock.farms.map((farm) => (
              <th key={farm.id}>{farm.name}</th>
            ))}
            <th>Total</th>
          </tr>
        </thead>

        <tbody>
          <>
            {movementsStocks.movementsArray.map((stockData, stockDataIndex) => (
              <>
                <tr style={styles.celula} key={stockDataIndex}>
                  <td style={{ whiteSpace: 'nowrap', backgroundColor: '#fff' }}>
                    {movementsStocks.movementDataArray[stockDataIndex].collect_id}
                  </td>
                  <td style={{ whiteSpace: 'nowrap', backgroundColor: '#fff' }}>
                    {movementsStocks.movementDataArray[stockDataIndex].movement_name}
                  </td>
                  {stockData.map((movimentoItem, movimentoItemIndex) => (
                    <td
                      style={{
                        color: ['E', 'TE'].includes(movementsStocks.movementDataArray[stockDataIndex].operation)
                          ? '#87CEFF'
                          : '#EE3B3B',
                        whiteSpace: 'nowrap',
                        backgroundColor: ['E', 'TE'].includes(movementsStocks.movementDataArray[stockDataIndex].operation)
                          ? 'blue'
                          : 'red'
                      }}
                      onClick={() =>
                        Number(movimentoItem) > 0 &&
                        handleOpenModalInvetory(
                          movementsStocks.movementDataArray[stockDataIndex].collect_id,
                          stock.farms[movimentoItemIndex].id
                        )
                      }
                      className="table-ligth"
                      key={movimentoItemIndex}
                    >
                      {movimentoItem}
                    </td>
                  ))}
                  <td style={styles.stock}>{stockData.reduce((a, b) => a + b, 0)}</td>
                </tr>
                <tr style={styles.celula} key={stockDataIndex + 1}>
                  <td style={styles.stock}></td>
                  <td style={styles.stock}>Estoque atual</td>
                  {movementsStocks.stocksArray[stockDataIndex].map((stockItem, stockItemIndex) => (
                    <td style={styles.stock} key={stockItemIndex}>
                      {stockItem}
                    </td>
                  ))}
                  <td style={styles.stock}>
                    {movementsStocks.stocksArray[stockDataIndex].reduce((a, b) => a + b, 0)}
                  </td>
                </tr>
              </>
            ))}
          </>
        </tbody>
        <ModalInventory data={dataForOpenModal} toggle={toggleModal} isOpen={isOpenInvetoryModal} />
      </Table>
    </div>
  );
};

export default withTranslation()(InvetoryTable);
