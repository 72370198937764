export const POST_LOGIN = '/login';
export const POST_JWT_LOGIN = '/login';
export const POST_REGISTER = '/register';

export const GET_FARMS = '/farms';
export const ADD_NEW_FARM = '/farms';
export const UPDATE_FARM = '/farms';
export const DELETE_FARM = '/farms';

export const GET_BREEDS = '/breeds';
export const ADD_NEW_BREED = '/breeds';
export const UPDATE_BREED = '/breeds';
export const DELETE_BREED = '/breeds';

export const GET_IRONS = '/irons';
export const ADD_NEW_IRON = '/irons';
export const UPDATE_IRON = '/irons';
export const DELETE_IRON = '/irons';

export const GET_KPICARDS = '/kpi-cards';
export const ADD_NEW_KPICARD = '/kpi-cards';
export const UPDATE_KPICARD = '/kpi-cards';
export const DELETE_KPICARD = '/kpi-cards';

export const GET_MOVEMENT_TYPES = '/movement-types';
export const ADD_NEW_MOVEMENT_TYPE = '/movement-types';
export const UPDATE_MOVEMENT_TYPE = '/movement-types';
export const DELETE_MOVEMENT_TYPE = '/movement-types';

// MOVEMENTS
export const GET_MOVEMENTS = '/movements';
export const ADD_NEW_MOVEMENT = '/movements';
export const UPDATE_MODAL_MOVEMENTS = '/stocks';
export const CREATE_MODAL_MOVEMENTS = '/stocks/create';
export const ADD_NEW_MOVEMENT_FILE = '/movements/import';
export const UPDATE_MOVEMENT = '/movements';
export const DELETE_MOVEMENT = '/movements';

// COLLECTS
export const GET_COLLECTS = '/collects';
// -- EARRINGS
export const COLLECTS_CHECK_EARRING = '/collects/check-earring';

export const GET_ANIMALS = '/animals';
export const UPDATE_ANIMAL = '/animals';

export const GET_PLANS = '/plans';
export const ATTACH_PLAN_PROFFILE = 'admin/plans';
export const ADD_NEW_PLAN = '/admin/plans';
export const UPDATE_PLAN = '/admin/plans';
export const DELETE_PLAN = '/admin/plans';

//REGISTER
export const POST_FAKE_REGISTER = '/post-fake-register';

//LOGIN
export const POST_FAKE_LOGIN = '/post-fake-login';
export const POST_FAKE_JWT_LOGIN = '/post-jwt-login';
export const POST_FAKE_PASSWORD_FORGET = '/fake-forget-pwd';
export const POST_FAKE_JWT_PASSWORD_FORGET = '/jwt-forget-pwd';
export const SOCIAL_LOGIN = '/social-login';

//PROFILE
export const POST_EDIT_JWT_PROFILE = '/admin/post-jwt-profile';
export const POST_EDIT_PROFILE = '/admin/post-fake-profile';

//PRODUCTS
export const GET_PRODUCTS = '/products';
export const GET_PRODUCTS_DETAIL = '/product';

//Mails
export const GET_INBOX_MAILS = '/inboxmails';
export const ADD_NEW_INBOX_MAIL = '/add/inboxmail';
export const DELETE_INBOX_MAIL = '/delete/inboxmail';

//starred mail
export const GET_STARRED_MAILS = '/starredmails';

//important mails
export const GET_IMPORTANT_MAILS = '/importantmails';

//Draft mail
export const GET_DRAFT_MAILS = '/draftmails';

//Send mail
export const GET_SENT_MAILS = '/sentmails';

//Trash mail
export const GET_TRASH_MAILS = '/trashmails';

//CALENDER
export const GET_EVENTS = '/events';
export const ADD_NEW_EVENT = '/add/event';
export const UPDATE_EVENT = '/update/event';
export const DELETE_EVENT = '/delete/event';
export const GET_CATEGORIES = '/categories';

//CHATS
export const GET_CHATS = '/chats';
export const GET_GROUPS = '/groups';
export const GET_CONTACTS = '/contacts';
export const GET_MESSAGES = '/messages';
export const ADD_MESSAGE = '/add/messages';

//ORDERS
export const GET_ORDERS = '/orders';
export const ADD_NEW_ORDER = '/add/order';
export const UPDATE_ORDER = '/update/order';
export const DELETE_ORDER = '/delete/order';

//CART DATA
export const GET_CART_DATA = '/cart';

//CUSTOMERS
export const GET_CUSTOMERS = '/customers';
export const ADD_NEW_CUSTOMER = '/add/customer';
export const UPDATE_CUSTOMER = '/update/customer';
export const DELETE_CUSTOMER = '/delete/customer';

//SHOPS
export const GET_SHOPS = '/shops';

//CRYPTO
export const GET_WALLET = '/wallet';
export const GET_CRYPTO_ORDERS = '/crypto/orders';

//INVOICES
export const GET_INVOICES = '/invoices';
export const GET_INVOICE_DETAIL = '/invoice';

//PROJECTS
export const GET_PROJECTS = '/projects';
export const GET_PROJECT_DETAIL = '/project';
export const ADD_NEW_PROJECT = '/add/project';
export const UPDATE_PROJECT = '/update/project';
export const DELETE_PROJECT = '/delete/project';

//TASKS
export const GET_TASKS = '/tasks';

//CONTACTS
export const GET_USERS = '/admin/users';
export const GET_USER_PROFILE = '/admin/users';
export const ADD_NEW_USER = '/admin/users';
export const UPDATE_USER = '/admin/users';
export const DELETE_USER = '/admin/users';

//dashboard charts data
export const GET_WEEKLY_DATA = '/weekly-data';
export const GET_YEARLY_DATA = '/yearly-data';
export const GET_MONTHLY_DATA = '/monthly-data';

export const TOP_SELLING_DATA = '/top-selling-data';

export const GET_EARNING_DATA = '/earning-charts-data';

export const GET_PRODUCT_COMMENTS = '/comments-product';

export const ON_LIKNE_COMMENT = '/comments-product-action';

export const ON_ADD_REPLY = '/comments-product-add-reply';

export const ON_ADD_COMMENT = '/comments-product-add-comment';

export const GET_ROLES = '/admin/roles';
export const ADD_NEW_ROLE = '/admin/roles';
export const UPDATE_ROLE = '/admin/roles';
export const DELETE_ROLE = '/admin/roles';

export const GET_TENANTS = '/admin/tenants';
export const ADD_NEW_TENANT = '/tenants';
export const UPDATE_TENANT = '/tenants';
export const DELETE_TENANT = '/tenants';

export const GET_PERMISSIONS = '/admin/permissions';
export const UPDATE_PERMISSION = '/admin/permissions';
export const ADD_NEW_PERMISSION = '/admin/permissions';
export const DELETE_PERMISSION = '/admin/permissions';

export const GET_PROFILES = '/admin/profiles';
export const UPDATE_PROFILE = '/admin/profiles';
export const ADD_NEW_PROFILE = '/admin/profiles';
export const DELETE_PROFILE = '/admin/profiles';

export const GET_ALL_PERMISSIONS = '/permissions';
export const GET_ALL_PROFILES = '/profiles';
export const ADD_NEW_PROFILE_PERMISSION = '/profiles';
export const DELETE_PROFILE_PERMISSION = '/profiles';

// export const GET_ALL_PERMISSIONS = '/permissions';
export const GET_ALL_ROLES = '/roles';
export const ADD_NEW_ROLE_PERMISSION = '/roles';
export const DELETE_ROLE_PERMISSION = '/roles';

// Stocks
export const GET_ALL_STOCKS = '/stocks';
