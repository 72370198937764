import {
  GET_ANALYTIC_GAINS,
  GET_ANALYTIC_GAINS_FAIL,
  GET_ANALYTIC_GAINS_SUCCESS,
  GET_SYNTHETIC_GAINS,
  GET_SYNTHETIC_GAINS_FAIL,
  GET_SYNTHETIC_GAINS_SUCCESS,
  GET_INVENTORY,
  GET_INVENTORY_FAIL,
  GET_INVENTORY_SUCCESS,
  SET_FILTER_ANALYTIC_GAINS,
  SET_FILTER_SYNTHETIC_GAINS,
  SET_FILTERED_ANALYTIC,
  SET_FILTERED_SYNTHETIC,
  SET_IS_LOADING_GAINS,
  SET_SYNTHETIC_FILTER_VALUES
} from './actionTypes';

export const getAnalyticGains = () => ({
  type: GET_ANALYTIC_GAINS
});
export const setFilterAnalyticGains = (analyticGains) => ({
  type: SET_FILTER_ANALYTIC_GAINS,
  payload: analyticGains
});

export const setFilteredAnalytic = (analyticGains) => ({
  type: SET_FILTERED_ANALYTIC,
  payload: analyticGains
});

export const getAnalyticGainsSuccess = (analyticGains) => ({
  type: GET_ANALYTIC_GAINS_SUCCESS,
  payload: analyticGains
});

export const getAnalyticGainsFail = (error) => ({
  type: GET_ANALYTIC_GAINS_FAIL,
  payload: error
});

export const setisLoadingGains = (loading) => ({
  type: SET_IS_LOADING_GAINS,
  payload: loading
});

export const getSyntheticGains = (filter) => ({
  type: GET_SYNTHETIC_GAINS,
  payload: filter
});

export const setSyntheticFilterValues = (values) => ({
  type: SET_SYNTHETIC_FILTER_VALUES,
  payload: values
});


export const setFilterSyntheticGains = (syntheticGains) => ({
  type: SET_FILTER_SYNTHETIC_GAINS,
  payload: syntheticGains
});

export const setFilteredSynthetic = (syntheticGains) => ({
  type: SET_FILTERED_SYNTHETIC,
  payload: syntheticGains
});

export const getSyntheticGainsSuccess = (syntheticGains) => ({
  type: GET_SYNTHETIC_GAINS_SUCCESS,
  payload: syntheticGains
});

export const getSyntheticGainsFail = (error) => ({
  type: GET_SYNTHETIC_GAINS_FAIL,
  payload: error
});

export const getInventory = () => ({
  type: GET_INVENTORY
});

export const getInventorySuccess = (syntheticGains) => ({
  type: GET_INVENTORY_SUCCESS,
  payload: syntheticGains
});

export const getInventoryFail = (error) => ({
  type: GET_INVENTORY_FAIL,
  payload: error
});
