export const GET_ANALYTIC_GAINS = 'GET_ANALYTIC_GAINS';
export const SET_FILTER_ANALYTIC_GAINS = 'SET_FILTER_ANALYTIC_GAINS';
export const SET_FILTERED_ANALYTIC = 'SET_FILTERED_ANALYTIC';
export const SET_IS_LOADING_GAINS = 'SET_IS_LOADING_GAINS';
export const GET_ANALYTIC_GAINS_FAIL = 'GET_ANALYTIC_GAINS_FAIL';
export const GET_ANALYTIC_GAINS_SUCCESS = 'GET_ANALYTIC_GAINS_SUCCESS';

export const GET_SYNTHETIC_GAINS = 'GET_SYNTHETIC_GAINS';
export const SET_FILTER_SYNTHETIC_GAINS = 'SET_FILTER_SYNTHETIC_GAINS';
export const SET_FILTERED_SYNTHETIC = 'SET_FILTERED_SYNTHETIC';
export const GET_SYNTHETIC_GAINS_FAIL = 'GET_SYNTHETIC_GAINS_FAIL';
export const GET_SYNTHETIC_GAINS_SUCCESS = 'GET_SYNTHETIC_GAINS_SUCCESS';
export const SET_SYNTHETIC_FILTER_VALUES = 'SET_SYNTHETIC_FILTER_VALUES';

export const GET_INVENTORY = 'GET_INVENTORY';
export const GET_INVENTORY_FAIL = 'GET_INVENTORY_FAIL';
export const GET_INVENTORY_SUCCESS = 'GET_INVENTORY_SUCCESS';
