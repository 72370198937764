import React, { useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as url from '../../helpers/url_helper';

import * as Yup from 'yup';
import { useFormik } from 'formik';

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Input,
  FormGroup,
  Label,
  Button,
  Form,
  FormFeedback
} from 'reactstrap';

import { showMessage, hideMessage } from 'store/message/actions';

import Select from 'react-select';

import Dropzone from 'react-dropzone';
import { Link } from 'react-router-dom';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

//Import Date Picker
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Message from '../../components/Message';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import {
  getBreeds as onGetBreeds,
  getIrons as onGetIrons,
  getMovementTypes as onGetMovementTypes,
  getFarms as onGetFarms,
  addNewMovement as onAddNewMovement,
  getAnimalsByFarm as onGetAnimalsByFarm
} from 'store/actions';
import { currencyMask, sendBancoDadosPrice } from 'utils/inputMask';
import formatBytes from 'utils/formatBytes';
import axiosApi from 'helpers/api_helper';
import { formatDate, removeDuplicates,formatDateByMoment } from 'utils/formats';
import MediaLibrary from 'components/MediaLibrary/MediaLibrary';
import useMediaLibraryStore from 'zustand/media-library/file-upload-store';
import { withTranslation } from 'react-i18next';
import ReactInputMask from 'react-input-mask';

const configHeaderFile = {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
};

const MovementExit = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  // MediaLibrary
  const { dataUpload } = useMediaLibraryStore();

  const [startDate, setstartDate] = useState(new Date());
  const [animalsInput, setAnimalsInput] = useState([]);

  const optionGroupEmpty = [{ label: '', options: [] }];

  const [selectedMovementType, setSelectedMovementType] = useState(null);
  const [optionGroupMovementType, setOptionGroupMovementType] = useState(optionGroupEmpty);

  const [selectedFarm, setSelectedFarm] = useState(null);
  const [optionGroupFarm, setOptionGroupFarm] = useState(optionGroupEmpty);

  const [selectedAnimal, setSelectedAnimal] = useState(null);
  const [optionGroupAnimal, setOptionGroupAnimal] = useState(optionGroupEmpty);

  const [isDropFile, setIsDropFile] = useState(false);
  const [movement, setMovement] = useState(null);
  const [movementTypeId, setMovementTypeId] = useState(null);

  const [selectedFiles, setselectedFiles] = useState([]);

  const { movementTypes } = useSelector((state) => ({
    movementTypes: state.MovementType.movementTypes
  }));
  const { farms } = useSelector((state) => ({ farms: state.Farm.farms }));
  const { animals } = useSelector((state) => ({ animals: state.Movement.animals }));

  const initialAnimals = []
  const [filterAnimals, setFilterAnimals] = useState(initialAnimals);

  const { error } = useSelector((state) => ({ error: state.Movement.error }));
  const [messageAlert, setMessageAlert] = useState('');
  const [typeMessage, setTypeMessage] = useState('');

  useEffect(() => {
    if (error != null && Object.keys(error).length !== 0) {
      showingMessage(error.message, 'warning');
    }
  }, [error]);

  useEffect(() => {
    dispatch(onGetMovementTypes());
    dispatch(onGetFarms());
  }, []);

  useEffect(() => {
    setOptionGroupFarm(formatDataForSelect('Farms', farms));
  }, [farms]);

  useEffect(() => {
    setOptionGroupMovementType(
      formatDataForSelect(
        'Movement Types',
        movementTypes.filter((item) => item.operation == 'S')
      )
    );
  }, [movementTypes]);

  useEffect(async () => {
    if (selectedFarm && selectedFarm.value > 0) {
      await dispatch(onGetAnimalsByFarm(selectedFarm.value));
    } else {
      setSelectedAnimal(optionGroupEmpty);
    }
  }, [selectedFarm]);

  useEffect(() => {
    setOptionGroupAnimal([
      {
        label:  `${props.t('Animals')}`,
        options: removeDuplicates(animals.filter((animal) => filterAnimalLogic(animal.id)).map((item) => {
          return {
            label: `${item.earring} - ${item.earring_eletric ? item.earring_eletric : ''}`,
            value: item.id
          };
        })
        )
      }
    ]);
  }, [animals, filterAnimals]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      collectName: (movement && movement.collectName) || '',
      movementDate: (movement && movement.movementDate) || '',
      Weight: (movement && movement.Weight) || ''
    },
    validationSchema: Yup.object({
      collectName: Yup.string().required('Please Enter Your collect name ')
    }),
    onSubmit: (values) => {
      if (isDropFile && selectedFiles.length <= 0) {
        console.log({ passou: 'if (isDropFile && selectedFiles.length <= 0)' });
        showingMessage('Inserir arquivo obrigatório', 'warning');
        return;
      }

      if (animalsInput.length > 0) {
        console.log({ passou: 'if (animalsInput.length > 0)' });
        for (var i = 0; animalsInput.length >= i; i++) {
          let animal = animals[i];

          if (animal?.weight.length <= 0) {
            showingMessage('Preencha os campos do animal', 'warning');
            return;
          }
        }
      }

      if (!isDropFile && animalsInput.length <= 0) {
        console.log({ passou: 'if(!isDropFile && animalsInput.length <= 0)' });
        showingMessage('Obrigatorio selecionar Importar ou Adicionar animal', 'warning');
        return;
      }

      const formData = new FormData();

      formData.append('timestamp', (Date.now() / 1000) | 0);
      formData.append('movement_type_id', movementTypeId);
      formData.append('farm_id', selectedFarm.value);
      formData.append('movement_date', formatDateByMoment(startDate));
      formData.append('collect[name]', values.collectName);
      formData.append('collect[amount]', sendBancoDadosPrice(values.amount || 0));
      formData.append('collect[freight]', sendBancoDadosPrice(values.freight || 0));
      formData.append('collect[other_values]', sendBancoDadosPrice(values.other_values || 0));
      formData.append('collect[arroba_price]', sendBancoDadosPrice(values.arroba_price || 0));

      if (dataUpload.imagesForUpload.length) {
        dataUpload.imagesForUpload.map((dataFile) => formData.append(`fotos[]`, dataFile));
      }

      const animalsConvertPrice = animalsInput.map((row) => {
        row.weight = sendBancoDadosPrice(row.weight);
        return row;
      })

      if (!isDropFile) {
        formData.append('collect[animals]', JSON.stringify(animalsConvertPrice));
      } else {
        formData.append('file', selectedFiles[0]);
      }

      tryServerApi(formData, !isDropFile ? url.ADD_NEW_MOVEMENT : url.ADD_NEW_MOVEMENT_FILE);
    }
  });

  const tryServerApi = async (movementData, URL) => {
    try {
      const response = await axiosApi.post(URL, movementData, configHeaderFile);
      const { message } = response?.data || {};

      showingMessage(message || props.t('Exit Created'), 'success');

      setTimeout(() => {
        history.push('/movements');
      }, 2000);
    } catch (error) {
      console.log(error);
      const { message } = error?.response?.data;
      showingMessage(message, 'warning');
    }
  };

  function formatDataForSelect(title, data = []) {
    if (data && data.length > 0) {
      return [
        {
          label: title,
          options: data.map((item) => {
            return { label: item.name, value: item.id };
          })
        }
      ];
    }
    return [
      {
        label: title,
        options: []
      }
    ];
  }

  // useEffect(() => {
  //     console.log('Animals2: ', animals);
  // }, [animals]);

  const startDateChange = (date) => {
    validation.handleChange('movementDate');
    setstartDate(date);
  };

  const handleAsyncActions = (action) => {
    if (error == null || Object.keys(error).length === 0) {
      showingMessage(`Exit ${action} Successfully`, 'success');
    }
  };

  const handleDateChange = (e) => {
    setstartDate(e.target.value);
    const value = e.target.value.replace(/\D/g, '');
    const formattedValue = value.replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3');
    validation.handleChange({
      target: {
        name: e.target.name,
        value: formattedValue
      }
    });
  };

  const showingMessage = (title, type) => {
    setMessageAlert(title);
    setTypeMessage(type);

    dispatch(showMessage());
    setTimeout(() => {
      dispatch(hideMessage());
    }, 2000);
  };

  // Function for Create Input Fields
  function handleAddFields() {
    let item1 = {
      animal_id: '',
      nickname: '',
      obs: '',
      weight: '',
      note: ''
    };
    setAnimalsInput([...animalsInput, item1]);
  }

  function handleAddWithouAnimalFields() {
    let item1 = {
      earring: '',
      earring_eletric: '',
      nickname: '',
      obs: '',
      weight: '',
      note: ''
    };
    setAnimalsInput([...animalsInput, item1]);
  }

  // Function for Remove Input Fields
  function handleRemoveFields(idx) {


    let newAnimals = [...animalsInput];
    let animalId = newAnimals[idx].animal_id;
    const animalFilter = filterAnimals.filter((animal) => animal !== animalId);
    setFilterAnimals(animalFilter);
    newAnimals.splice(idx, 1);
    setAnimalsInput(newAnimals);
  }

  function handleSelectFarm(selectedFarm) {
    setSelectedFarm(selectedFarm);
  }

  function handleSelectAnimal(selectedAnimal) {
    setSelectedAnimal(selectedAnimal);
  }


  const handleNumberChangeAdd = (idx, e) => {
    let newanimals = [...animalsInput];
    const value = e.target.value;
    const formattedValue = formatNumber(value);
    newanimals[idx].weight = formattedValue;
    setAnimalsInput(newanimals);
    validation.handleChange({
      target: {
        name: `${idx}${e.target.name}`,
        value: formattedValue
      }
    });
  };


  function handleSelectMovementType(selectedMovementType) {
    setSelectedMovementType(selectedMovementType);
    setMovementTypeId(selectedMovementType.value);
  }

  function handleAddEarring(idx, value) {
    let newAnimals = [...animalsInput];
    newAnimals[idx].earring = value;
    setAnimalsInput(newAnimals);
  }

  
  function handleAddEarringEletric(idx, value) {
    let newAnimals = [...animalsInput];
    newAnimals[idx].earring_eletric = value;
    setAnimalsInput(newAnimals);
  }

  function handleAddPrice(idx, value) {
    let newAnimals = [...animalsInput];
    newAnimals[idx].price = value;
    setAnimalsInput(newAnimals);
  }

  // function handleAddWeight(idx, value) {
  //   let newAnimals = [...animalsInput];
  //   newAnimals[idx].weight = value;
  //   setAnimalsInput(newAnimals);
  // }

  function handleAddWeight(idx, value) {
    // Substitui vírgula por ponto
    let formattedValue = value.replace(',', '.');

    // Verifica se o valor é um número válido após a formatação
    if (!isNaN(formattedValue) && formattedValue.trim() !== '') {
      // Certifique-se de que animalsInput é um array
      if (Array.isArray(animalsInput)) {
        // Cria uma cópia do array de animais
        let newAnimals = [...animalsInput];

        // Atualiza o peso do animal no índice fornecido
        newAnimals[idx].weight = parseFloat(formattedValue);

        // Atualiza o estado com o novo array de animais
        setAnimalsInput(newAnimals);
      } else {
        console.error('animalsInput is not an array');
      }
    }
  }

  function handleAddNote(idx, value) {
    let newAnimals = [...animalsInput];
    newAnimals[idx].note = value;
    setAnimalsInput(newAnimals);
  }

  function handleAddAnimal(idx, value) {
    let newAnimals = [...animalsInput];
    const animalFilter = filterAnimals.filter((animal) => animal !== newAnimals[idx].animal_id);
    animalFilter.push(value);
    setFilterAnimals(animalFilter);
    newAnimals[idx].animal_id = value;
    setAnimalsInput(newAnimals);
  }

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size)
      })
    );

    setselectedFiles(files);
  }

  function handleChangeFilesMediaLibrary(dataUpload) {
    console.log({ dataUpload });
  }

  const formatNumber = (value) => {
    if (!value) return '';
    const cleanedValue = value.replace(/\D/g, '');
    const formattedValue = new Intl.NumberFormat('pt-BR', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(cleanedValue / 100);
    return formattedValue;
  };

  const handleNumberChange = (e) => {
    const value = e.target.value;
    const formattedValue = formatNumber(value);
    validation.handleChange({
      target: {
        name: e.target.name,
        value: formattedValue
      }
    });
  };

  const filterAnimalLogic = (row) => {
    if (!Array.isArray(filterAnimals)) {
      return true;
    }
    if (!filterAnimals.includes(row)) {
      return true;
    }

    return false
  }

  return (
    <>
      <div className="page-content">
        <Message title={messageAlert} type={typeMessage} />
        <MetaTags>
          <title>Create New Outgoing Movement | SistemasW - Fazenda</title>
          <description>Create New Outgoing Movement | SistemasW - Fazenda</description>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title={props.t('Movements')}
            breadcrumbItem={props.t('Create new outgoing movement')}
          />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">{props.t('Create new outgoing movement')}</CardTitle>
                  <Form
                    id="formSubmit"
                    className="outer-repeater"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}>
                    <div data-repeater-list="outer-group" className="outer">
                      <div data-repeater-item className="outer">
                        <FormGroup>
                          <Row>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label htmlFor="collectName">{props.t('Collects')}</Label>
                                <Input
                                  id="collectName"
                                  name="collectName"
                                  type="text"
                                  className="form-control"
                                  placeholder={props.t('Enter the name of the collection')}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.collectName || ''}
                                  invalid={
                                    validation.touched.collectName && validation.errors.collectName
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.collectName && validation.errors.collectName ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.collectName}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>

                            <Col lg="3">
                              <div className="mb-3">
                                <Label>{props.t('Farm')}</Label>
                                <Select
                                  id="farmId"
                                  name="farmId"
                                  value={selectedFarm}
                                  onChange={handleSelectFarm}
                                  options={optionGroupFarm}
                                />
                              </div>
                            </Col>

                            <Col lg="3">
                              <div className="mb-3">
                                <Label>{props.t('Data')}</Label>
                                <ReactInputMask
                                  mask="99/99/9999"
                                  value={validation.values.movementDate || ''}
                                  onChange={handleDateChange}
                                  onBlur={validation.handleBlur}
                                  className="form-control">
                                  {(inputProps) => (
                                    <Input
                                      {...inputProps}
                                      type="text"
                                      id="movementDate"
                                      name="movementDate"
                                      placeholder="dd/mm/yyyy"
                                      invalid={
                                        validation.touched.movementDate &&
                                          validation.errors.movementDate
                                          ? true
                                          : false
                                      }
                                    />
                                  )}
                                </ReactInputMask>
                                {validation.touched.movementDate &&
                                  validation.errors.movementDate ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.movementDate}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>

                            <Col lg="3">
                              <div className="md-3">
                                <Label>{props.t('Type')}</Label>
                                <Select
                                  id="movementTypeId"
                                  name="movementTypeId"
                                  value={selectedMovementType}
                                  onChange={handleSelectMovementType}
                                  options={optionGroupMovementType}
                                />
                              </div>
                            </Col>
                          

                            <Col lg="3">
                              <div className="mb-3">
                                <Label htmlFor="collectName">{props.t('Amount')}</Label>
                                <Input
                                  id="amount"
                                  name="amount"
                                  type="text"
                                  className="form-control"
                                  placeholder={props.t('Enter amount the collect')}
                                  onChange={(e) => validation.handleChange(currencyMask(e))}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.amount
                                      ? currencyMask(validation.values.amount)
                                      : ''
                                  }
                                  invalid={
                                    validation.touched.amount && validation.errors.amount
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.amount && validation.errors.amount ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.amount}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>

                            <Col lg="3">
                              <div className="mb-3">
                                <Label htmlFor="collectName">{props.t('Freight')}</Label>
                                <Input
                                  id="freight"
                                  name="freight"
                                  type="text"
                                  className="form-control"
                                  placeholder={props.t('Enter freight the collect')}
                                  onChange={(e) => validation.handleChange(currencyMask(e))}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.freight
                                      ? currencyMask(validation.values.freight)
                                      : ''
                                  }
                                  invalid={
                                    validation.touched.freight && validation.errors.freight
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.freight && validation.errors.freight ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.freight}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>

                            <Col lg="3">
                              <div className="mb-3">
                                <Label htmlFor="collectName">{props.t('Other values')}</Label>
                                <Input
                                  id="other_values"
                                  name="other_values"
                                  type="text"
                                  className="form-control"
                                  placeholder={props.t('Enter other values the collect')}
                                  onChange={(e) => validation.handleChange(currencyMask(e))}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.other_values
                                      ? currencyMask(validation.values.other_values)
                                      : ''
                                  }
                                  invalid={
                                    validation.touched.other_values &&
                                      validation.errors.other_values
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.other_values &&
                                  validation.errors.other_values ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.other_values}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>

                            <Col lg="3">
                              <div className="mb-3">
                                <Label htmlFor="collectName">{props.t('Arroba price')}</Label>
                                <Input
                                  id="arroba_price"
                                  name="arroba_price"
                                  type="text"
                                  className="form-control"
                                  placeholder={props.t('Enter arroba_price the collect')}
                                  onChange={(e) => validation.handleChange(currencyMask(e))}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.arroba_price
                                      ? currencyMask(validation.values.arroba_price)
                                      : ''
                                  }
                                  invalid={
                                    validation.touched.arroba_price &&
                                      validation.errors.arroba_price
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.arroba_price &&
                                  validation.errors.arroba_price ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.arroba_price}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                        </FormGroup>

                        <div className="inner-repeater mb-4">
                          <div className="inner form-group mb-0">
                            <div className="">
                              <Label className="col-form-label col-lg-2">
                                {props.t('Animals')}
                              </Label>
                            </div>
                            <Row>
                              <div className="inner col-lg-10 ml-md-auto" id="repeater">
                                {animalsInput.map((field, key) => (
                                  <div
                                    key={key}
                                    id={'nested' + key}
                                    className="mb-3 row align-items-center">
                                    <FormGroup className="" row>
                                      {field.animal_id != undefined ? (
                                        <Col lg="3">
                                          <div className="md-3">
                                            <Label>{props.t('Add Animals')}</Label>
                                            <Select
                                              defaultValue={field.animal_id}
                                              value={selectedAnimal}
                                              onChange={(animal) => {
                                                handleSelectAnimal();
                                                handleAddAnimal(key, animal.value);
                                              }}
                                              options={optionGroupAnimal}
                                              classNamePrefix="select2-selection"
                                            />
                                          </div>
                                        </Col>
                                      ) : (
                                        ''
                                      )}

                                      {field.earring != undefined ? (
                                        <Col md="2">
                                          <Label>{props.t('Earring')}</Label>

                                          <input
                                            type="text"
                                            className="inner form-control"
                                            defaultValue={field.earring}
                                            placeholder={props.t('Enter Earring')}
                                            onChange={() =>
                                              handleAddEarring(key, event.target.value)
                                            }
                                          />
                                        </Col>
                                      ) : (
                                        ''
                                      )}

                                      <Col lg="2">
                                        <div className="md-3">
                                          <Label>{props.t('Weight')}</Label>
                                          <Input
                                            id="weight"
                                            name="weight"
                                            className="form-control"
                                            placeholder={props.t('Enter Weight')}
                                            value={validation.values[`${key}weight`]}
                                            onChange={(e) => handleNumberChangeAdd(key, e)}
                                            onBlur={validation.handleBlur}

                                            invalid={
                                              validation.touched[`${key}weight`] && validation.errors[`${key}weight`]
                                                ? true
                                                : false
                                            }
                                          />
                                          {validation.touched[`${key}weight`] &&
                                            validation.errors[`${key}weight`] ? (
                                            <FormFeedback type="invalid">
                                              {validation.errors[`${key}weight`]}
                                            </FormFeedback>
                                          ) : null}
                                        </div>
                                      </Col>


                                      {field.earring != undefined ? (
                                        <Col md="2">
                                          <Label>{props.t('Earring Eletric')}</Label>

                                          <input
                                            type="text"
                                            className="inner form-control"
                                            defaultValue={field.earring_eletric}
                                            placeholder={props.t('Enter Earring Eletric')}
                                            onChange={() =>
                                              handleAddEarring(key, event.target.value)
                                            }
                                          />
                                        </Col>
                                      ) : (
                                        ''
                                      )}

                                      <Col lg="4" className="row">
                                        <div className="lg-9">
                                          <Label>{props.t('Obs')}.:</Label>
                                          <input
                                            type="text"
                                            className="inner form-control"
                                            defaultValue={field.note}
                                            placeholder={props.t('Enter Note')}
                                            onChange={() => handleAddNote(key, event.target.value)}
                                          />
                                        </div>
                                      </Col>

                                      <Col lg="1" style={{ marginTop: 26 }} className="row">
                                        <div className="md-4">
                                          <Button
                                            color="danger"
                                            className="inner form-control"
                                            onClick={() => {
                                              handleRemoveFields(key);
                                            }}
                                            block>
                                            <i className="mdi mdi-trash-can"></i>
                                          </Button>
                                        </div>
                                      </Col>
                                    </FormGroup>
                                  </div>
                                ))}
                              </div>
                            </Row>
                          </div>
                          {isDropFile && (
                            <>
                              <Dropzone
                                onDrop={(acceptedFiles) => {
                                  handleAcceptedFiles(acceptedFiles);
                                }}>
                                {({ getRootProps, getInputProps }) => (
                                  <div className="dropzone">
                                    <div className="dz-message needsclick mt-2" {...getRootProps()}>
                                      <input {...getInputProps()} />
                                      <div className="mb-3">
                                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                                      </div>
                                      <h4>{props.t('Drop files here or click to upload')}.</h4>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                              <Row>
                                <div className="dropzone-previews mt-3" id="file-previews">
                                  {selectedFiles.map((f, i) => {
                                    return (
                                      <Card
                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                        key={i + '-file'}>
                                        <Row>
                                          <div className="p-2">
                                            <Row className="align-items-center">
                                              <Col style={{ marginLeft: 20 }}>
                                                <Link
                                                  to="#"
                                                  className="text-muted font-weight-bold">
                                                  {f.name}
                                                </Link>
                                                <p className="mb-0">
                                                  <strong>{f.formattedSize}</strong>
                                                </p>
                                              </Col>
                                            </Row>
                                          </div>
                                        </Row>
                                      </Card>
                                    );
                                  })}
                                </div>
                                {selectedFiles.length > 0 && (
                                  <Col md="2" style={{ marginTop: 10, marginBottom: 25 }}>
                                    <div className="md-4">
                                      <Button
                                        color="danger"
                                        className="inner form-control"
                                        onClick={() => {
                                          setselectedFiles([]);
                                          setIsDropFile(false);
                                        }}
                                        block>
                                        <i className="mdi mdi-trash-can"></i>
                                      </Button>
                                    </div>
                                  </Col>
                                )}
                              </Row>
                            </>
                          )}
                          <Row className="justify-content-start">
                            <Col lg="10">
                              <Button
                                color="success"
                                className="inner "
                                onClick={() => {
                                  isDropFile ? null : handleAddFields();
                                }}>
                                {props.t('Add Animals')}
                              </Button>

                              <Button
                                color="success"
                                className="inner"
                                style={{ marginLeft: 8 }}
                                onClick={() => {
                                  isDropFile ? null : handleAddWithouAnimalFields();
                                }}>
                                {props.t('Add Animals2')}
                              </Button>

                              <Button
                                color="success"
                                className="inner"
                                style={{ marginLeft: 8 }}
                                onClick={() => {
                                  animalsInput.length > 0 ? null : setIsDropFile((v) => !v);
                                }}>
                                {props.t('Import')}
                              </Button>
                              {isDropFile > 0 && (
                              <Button
                                color="primary"
                                className="inner"
                                style={{ marginLeft: 10 }}
                                onClick={()  =>  window.open(`${process.env.REACT_APP_SYSTEM_URL.replace('/api/v1','')}/import/iron_import_o.csv`)}
                               >
                                {props.t('Download Sheet')}
                              </Button>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">{props.t('Photo gallery')}</CardTitle>
                  <FormGroup>
                    <Row form>
                      <MediaLibrary
                        resource={'Collect'}
                        isSendForCreate
                        isEditableItemName
                        onChange={handleChangeFilesMediaLibrary}
                      />
                    </Row>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="6">
                      <div className="mt-4" style={{ textAlign: 'right' }}>
                        <button type="submit" form="formSubmit" className="btn btn-primary ">
                          {isDropFile ? 'Importar Arquivos' : 'Salvar'}
                        </button>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="text-left mt-4">
                        <button
                          className="btn btn-danger"
                          onClick={() => history.push('/movements')}>
                          {'Cancel'}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

MovementExit.propTypes = {
  breeds: PropTypes.array,
  irons: PropTypes.array,
  farms: PropTypes.array,
  animals: PropTypes.array,
  onGetBreeds: PropTypes.func,
  onGetIrons: PropTypes.func,
  onGetFarms: PropTypes.func,
  onGetAnimalsByFarm: PropTypes.func,
  addNewMovement: PropTypes.func
};

export default withTranslation()(MovementExit);
