import React, { useState, useEffect, useCallback } from 'react';
import { Button, Card, CardBody, Col, Input, Row } from 'reactstrap';

import Select from 'react-select';
import { getFarms } from 'helpers/backend_fazenda_helper';
import { withTranslation } from 'react-i18next';
import useStockStore from 'zustand/stocks/stocks-store';

const DropFilter = (props, { onFilter }) => {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(options.length ? options : null);
  const [date, setDate] = useState({ de: undefined, ate: undefined });
  const {  getStocks } = useStockStore();
  

  const tryFarm = useCallback(() => {
    getFarms().then(({ data }) => {
      const farms = data.map((farm) => {
        return {
          value: farm.id,
          label: farm.name
        };
      });

      setOptions(farms);
    });
  }, [setOptions, getFarms]);

  const filter = (data) => {
    const filterFormat = {
      farm_ids : [],
      initial_date : null,
      final_date : null,
    }
    filterFormat.farm_ids = data.farms.map((farm) => {return farm.value});
    if(data.de && data.ate){
      filterFormat.initial_date = data.de;
      filterFormat.final_date = data.final_date;
    }
    getStocks(filterFormat);


  }

  useEffect(() => {
    tryFarm();
  }, [tryFarm]);

  useEffect(() => {
    const objectOptions = [];
    options.map((option) => {
      objectOptions.push(option);
    });
    setSelectedOption(objectOptions);
    console.log({ options, objectOptions });
  }, [options]);
  return (
    <Row>
      <Card style={{ margin: 0, padding: 0 }}>
        <CardBody style={{ margin: 0, paddingRight: '7px', paddingLeft: '7px' }}>
          <Row>
            <Col md={3}>
              <strong>{props.t("From")}</strong>
              <Input
                onChange={(event) => setDate({ ...date, de: event.target.value })}
                type="date"
                name="de"
                id="de"
                placeholder="De"
                value={date.de}
              />
            </Col>
            <Col md={3}>
              <strong>{props.t("To")}</strong>
              <Input
                onChange={(event) => setDate({ ...date, ate: event.target.value })}
                type="date"
                name="ate"
                id="ate"
                placeholder="Ate"
                value={date.ate}
              />
            </Col>
            <Col>
              <strong>{props.t("Select farms")}</strong>
              <Select
                isMulti
                defaultValue={options}
                onChange={setSelectedOption}
                options={options}
              />
            </Col>
          </Row>
          <Row>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
                gap: '5px',
                top: '18px'
              }}>
              <Button
                color="primary"
                onClick={() => {
                  filter({ de: date.de, ate: date.ate, farms: selectedOption });
                }}>
                {props.t("Search filter")}
              </Button>
              <Button color="danger">{props.t("Clear")}</Button>
            </div>
          </Row>
        </CardBody>
      </Card>
    </Row>
  );
};

export default withTranslation()(DropFilter);

