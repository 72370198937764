import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MetaTags from 'react-meta-tags';
import { Container, Row, Col, Card, CardBody, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useHistory } from 'react-router-dom';

import Distribution from './Distribution';
import CapacidadeFazendas from './CapacidadeFazendas';
import Stocks from './Stocks';
import SetupWizard from '../../components/PagesComponents/SetupWizard';
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

//i18n
import { withTranslation } from 'react-i18next';

import { intValueMask } from 'utils/inputMask';
import DropFilter from './DropFilter';
import Can from 'components/Can';
import useDashboardStore from 'zustand/dashboard/dashboard-store';
import useStockStore from 'zustand/stocks/stocks-store';
import { useFarmsStore } from 'zustand/farms/farms-store';
import Loading from 'components/Loading';
import DistributionTwo from './DistributionTwo';

const Dashboard = (props) => {
  const navigate = useHistory();

  const [modal, setmodal] = useState(false);
  const { cards, isLoading: isLoadingDashboard, getCards } = useDashboardStore();
  const { advancedStocks, isLoading: isLoadingStock, getAdvancedStocks } = useStockStore();
  const { farms, getFarms, isLoading: isLoadingFarms } = useFarmsStore();
  const [filters, setFilters] = useState();

  const isLoading = useMemo(
    () => isLoadingDashboard || isLoadingStock || isLoadingFarms,
    [isLoadingDashboard, isLoadingStock, isLoadingFarms]
  );

  const tryApis = async (filters) => {
    await getCards(filters);
    await getAdvancedStocks(filters);
    await getFarms(filters);
  };

  const tryApisCallback = useCallback(async () => {
    await tryApis(filters);
  }, [tryApis, filters]);

  useEffect(() => {
    tryApisCallback();
  }, []);

  const handleFilter = (filters) => {
    tryApis(filters);
    setFilters(filters);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | Farm SystemW</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={props.t('Dashboards')} breadcrumbItem={props.t('Dashboard')} />
          <Row>
            <Can permissions={['index_filter_dashboard']}>
              <DropFilter onFilter={handleFilter} />
            </Can>
          </Row>
          {isLoading ? (
            <Loading isLoading={isLoading} />
          ) : (
            <Row>
              <Col xl="12">
                <Row>
                  {/* Reports Render */}
                  {cards?.map((report, key) => (
                    <Col md="3" key={'_col_' + key}>
                      <Card className="mini-stats-wid">
                        <Can permissions={['index_card_dashboard']}>
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="text-muted fw-medium">{props.t(report.name)}</p>
                                <h4 className="mb-0">{intValueMask(report.count)}</h4>
                              </div>
                              <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-primary">
                                  <i className={'bx bx-copy-alt font-size-24'}></i>
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Can>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Col>

              <Col xl="12">
                <Row>
                  {farms
                    // ?.filter(
                    //   (farm) => filters?.farms.filter((filter) => filter.value === farm.id).length
                    // )
                    ?.map((farm, key) => (
                      <Col xl="4" key={farm.id}>
                        <Can permissions={['index_capacit_farm_dashboard']}>
                          <CapacidadeFazendas farm={farm} />
                        </Can>
                      </Col>
                    ))}
                </Row>
              </Col>

              <Col xl="12">
                <Row>
                  <Col xl="4">
                    <Can permissions={['index_stock_dashboard']}>
                      <Stocks filters={filters} {...props} />
                    </Can>
                  </Col>
                  {advancedStocks.length > 0 &&
                    advancedStocks
                      // .filter(
                      //   (itemStock) =>
                      //     filters?.farms.filter((filter) => filter.value == itemStock.farm_id)
                      //       .length > 0
                      // )
                      ?.map((itemStock) => (
                        <Col key={'stockId' + itemStock.farm_id} xl="4">
                          <Can permissions={['index_distribution_dashboard']}>
                            <Distribution stock={itemStock} />
                          
                          </Can>
                        </Col>
                      ))}

                      {advancedStocks.length > 0 &&
                    advancedStocks
                      // .filter(
                      //   (itemStock) =>
                      //     filters?.farms.filter((filter) => filter.value == itemStock.farm_id)
                      //       .length > 0
                      // )
                      ?.map((itemStock) => (
                        <Col key={'stockId' + itemStock.farm_id} xl="4">
                          <Can permissions={['index_distribution_dashboard']}>
                            <DistributionTwo stock={itemStock} />
                          
                          </Can>
                        </Col>
                      ))}
                </Row>
              </Col>

              <div>
                <hr />
              </div>
            </Row>
          )}
        </Container>
      </div>

      <Modal
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        // fullscreen={true}
        size="xl"
        tabIndex="-1"
        toggle={() => {
          setmodal(!modal);
        }}>
        <div>
          <ModalHeader
            toggle={() => {
              setmodal(!modal);
              navigate.push('/logout');
            }}>
            Order Details
          </ModalHeader>
          <ModalBody>
            <SetupWizard />
          </ModalBody>
        </div>
      </Modal>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
  onGetFarms: PropTypes.func
};

export default withTranslation()(Dashboard);
