// const accessToken =
//   "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6ImFkbWluIiwiYWRtaW4iOnRydWUsImp0aSI6ImQ2MTEwYzAxLWMwYjUtNDUzNy1iNDZhLTI0NTk5Mjc2YjY1NiIsImlhdCI6MTU5MjU2MDk2MCwiZXhwIjoxNTkyNTY0NjE5fQ.QgFSQtFaK_Ktauadttq1Is7f9w0SUtKcL8xCmkAvGLw"
// export default accessToken

import { USE_TENANT_STORAGE } from 'constants/tenant';

const getAuthenticatedUser = () => {
  if (!localStorage.getItem('authUser')) return '';
  console.log("token",JSON.parse(localStorage.getItem('authUser')));
  return JSON.parse(localStorage.getItem('authUser'));
};

const accessToken = getAuthenticatedUser().token;

export default accessToken;
