import {
  GET_ANALYTIC_GAINS_SUCCESS,
  GET_ANALYTIC_GAINS_FAIL,
  GET_SYNTHETIC_GAINS_SUCCESS,
  GET_SYNTHETIC_GAINS_FAIL,
  GET_INVENTORY_SUCCESS,
  GET_INVENTORY_FAIL,
  SET_FILTER_ANALYTIC_GAINS,
  SET_FILTERED_ANALYTIC,
  SET_FILTER_SYNTHETIC_GAINS,
  SET_FILTERED_SYNTHETIC,
  SET_IS_LOADING_GAINS,
  SET_SYNTHETIC_FILTER_VALUES
} from './actionTypes';

const INIT_STATE = {
  analyticGains: [],
  analyticFilteredGains: [],
  isLoadingGains: true,
  analyticFiltered: false,
  syntheticGains: [],
  syntheticFilteredGains: [],
  syntheticFiltered: false,
  syntheticFilterValues : {},
  inventory: [],
  error: {}
};

const ReportGains = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ANALYTIC_GAINS_SUCCESS:
      return {
        ...state,
        isLoadingGains: false,
        analyticGains: action.payload
      };

    case SET_FILTER_ANALYTIC_GAINS:
      return {
        ...state,
        analyticFilteredGains: action.payload,
        analyticFiltered: true
      };

    case SET_FILTERED_ANALYTIC:
      return {
        ...state,
        analyticFiltered: action.payload
      };

    case SET_IS_LOADING_GAINS:
      return {
        ...state,
        isLoadingGains: action.payload
      };

    case SET_FILTERED_SYNTHETIC:
      return {
        ...state,
        syntheticFiltered: action.payload
      };

      case SET_SYNTHETIC_FILTER_VALUES:
        return {
          ...state,
          syntheticFilterValues: action.payload
        };

    case GET_ANALYTIC_GAINS_FAIL:
      return {
        ...state,
        error: action.payload
      };
    case GET_SYNTHETIC_GAINS_SUCCESS:
      return {
        ...state,
        isLoadingGains: false,
        syntheticGains: action.payload
      };

    case SET_FILTER_SYNTHETIC_GAINS:
      return {
        ...state,
        syntheticFilteredGains: action.payload,
        syntheticFiltered: true
      };

    case GET_SYNTHETIC_GAINS_FAIL:
      return {
        ...state,
        error: action.payload
      };
    case GET_INVENTORY_SUCCESS:
      return {
        ...state,
        inventory: action.payload
      };

    case GET_INVENTORY_FAIL:
      return {
        ...state,
        error: action.payload
      };

    default:
      return state;
  }
};

export default ReportGains;
