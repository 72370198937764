import React from 'react';
import moment from 'moment';
export const currencyMask = (e) => {
  let value = null;
  if (isObject(e)) {
    value = e.target.value;
  } else {
    value = e.toString();
  }
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d)(\d{2})$/, '$1,$2');
  value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');
  if (isObject(e)) {
    e.target.value = value;
    return e;
  } else {
    return value;
  }
};

const isObject = (obj) => {
  return Object.prototype.toString.call(obj) === '[object Object]';
};

export const isObjectExport = (obj) => {
  return Object.prototype.toString.call(obj) === '[object Object]';
};

export const forceDownload = ({ fileUrl, fileName }) => {
  
    // Cria um elemento <a> invisível
    const link = document.createElement('a');
    link.href = fileUrl;
    
    // Define o nome do arquivo para download
    link.download = fileName || 'download';
    
    // Adiciona o link ao documento
    document.body.appendChild(link);
    
    // Simula o clique no link
    link.click();
    
    // Remove o link do documento
    document.body.removeChild(link);
  };
  

export const currencyMaskValue = (e) => {
  console.log('oquetachegandoaqui', value);
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d)(\d{2})$/, '$1,$2');
  value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');
  e.target.value = value;
  return e;
};

export const intMask = (e) => {
  let value = e.target.value;
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d)(\d{2})$/, '$1.$2');
  // value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
  e.target.value = value;
  return e;
};

export const currencyValueMask = (value) => {
  let valueMask = Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2
  }).format(value);

  return valueMask;
};

export const decimalValueMask = (value) => {
  let valueMask = Intl.NumberFormat('pt-BR', {
    style: 'decimal',
    currency: 'BRL',
    maximumFractionDigits: 2
  }).format(value);

  return valueMask;
};

export const intValueMask = (value) => {
  let valueMask = Intl.NumberFormat('id').format(value);

  return valueMask;
};

function toFixedString(num, fixed) {
  var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
  return num.toString().match(re)[0].replaceAll('.',',');
}

export function formatPrice(value, style = 'decimal', minimumFractionDigits = 2, maximumFractionDigits = 2) {
  let valueMask = Intl.NumberFormat('pt-BR', {
    style,
    currency: 'BRL',
    minimumFractionDigits,
    maximumFractionDigits
  }).format(value);

  return valueMask;
}


export function sendBancoDadosPrice(price) {
  let value = price.toString();
  value = value.replaceAll('.', '');
  value = value.replace(',', '.');
  return value;
}

// export function formatDateBr(dataField) {
//   let data = new Date(dataField);
//   const day = String(data.getDate()).padStart(2, '0');
//   const month = String(data.getMonth() + 1).padStart(2, '0');
//   const year = data.getFullYear();
//   const dataView = `${day}/${month}/${year}`;
//   return dataView;
// }

export function formatDateBr(dataField) {
  let data =  moment(dataField).format('DD/MM/YYYY');
  return data;
}

export function removeDuplicates(arr) {
  return arr.filter((item,
      index) => arr.indexOf(item) === index);
}


export function formaIntBr(int) {
  const intBR = parseInt(int).toLocaleString('pt-BR');
  return intBR;
}


export function formatDateDb(dataField) {
  let data = new Date(dataField);
  const day = String(data.getDate()).padStart(2, '0');
  const month = String(data.getMonth() + 1).padStart(2, '0');
  const year = data.getFullYear();
  const dataDb = `${year}-${month}-${day}`;
  return dataDb;
}
