import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Table,
  Form,
  FormGroup,
  Label,
  Input
} from 'reactstrap';
import { formatPrice } from 'utils/inputMask';
import MediaLibrary from 'components/MediaLibrary/MediaLibrary';
import { showMessage, hideMessage } from 'store/message/actions';
import {
  ENTRADA,
  MOVIMENTO_OPERACAO_AVALIACAO,
  MOVIMENTO_OPERACAO_SAIDA,
  MOVIMENTO_OPERACAO_TRANSFERENCIA_ENTRADA,
  MOVIMENTO_OPERACAO_TRANSFERENCIA_SAIDA
} from 'constants/movement';
import useStockStore from 'zustand/stocks/stocks-store';
import { useFarmsStore } from 'zustand/farms/farms-store';
import Logo from '../../assets/images/wshare.png';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { createWorkbook, createWorksheet } from 'lib/exceljs/Services';
import { useSelector, useDispatch } from 'react-redux';

import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import * as url from '../../helpers/url_helper';
import axiosApi from 'helpers/api_helper';
import toast, { Toaster } from 'react-hot-toast';
import { currencyMask, sendBancoDadosPrice, forceDownload } from 'utils/inputMask';
import { getAnimalsByFarm as onGetAnimalsByFarm } from 'store/actions';

const MovementsModal = (props) => {
  const { isOpen, toggle, movement } = props;
  const { getMovementsByCollectId, movementsByCollect, isLoading } = useStockStore();
  const {
    allIrons: ironsWhenStore,
    allBreeds: breedsWhenStore,
    getAllBreed,
    getAllIron
  } = useFarmsStore();
  const configHeaderFile = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };

  const optionsIron = useMemo(() => {
    return ironsWhenStore?.map((iron) => ({ value: iron.id, label: iron.name })) || [];
  }, [ironsWhenStore]);

  const optionsBreeds = useMemo(() => {
    return breedsWhenStore?.map((breed) => ({ value: breed.id, label: breed.name })) || [];
  }, [breedsWhenStore]);

  const { animals } = useSelector((state) => ({ animals: state.Movement.animals }));
  const dispatch = useDispatch();

  const [localMovements, setLocalMovements] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [selectedAnimals, setSelectedAnimals] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const [selectedIronOption, setSelectedIronOption] = useState(ironsWhenStore);

  useEffect(() => {
    setSelectedAnimals([
      {
        label: props.t('Animals'),
        options: animals
          .filter((animal) => !localMovements.some((movement) => movement.animal_id === animal.id))
          .map((item) => ({
            label: `${item.earring}${item.earring_eletric ? ` - ${item.earring_eletric}` : ''}`,
            value: item.id
          }))
      }
    ]);
  }, [animals, localMovements]);

  useEffect(() => {
    if (movement && movement.farm_id) {
      dispatch(onGetAnimalsByFarm(movement.farm_id));
      console.log(movement);
    }
  }, [movement]);

  const toggleEditModal = () => setEditModalOpen(!editModalOpen);

  const handleEditClick = (item) => {
    setCurrentItem(item);
    setIsCreating(false);
    toggleEditModal();
  };

  const handleAddClick = () => {
    setCurrentItem({ earring: '', weight: '', iron_name: '', note: '' });
    setIsCreating(true);
    toggleEditModal();
  };

  const handleDeleteClick = (itemId) => {
    setLocalMovements((prevMovements) => prevMovements.filter((item) => item.id !== itemId));
  };

  const handleSaveEdit = async () => {
    const currentItemConverted = {
      ...currentItem,
      weight: sendBancoDadosPrice(currentItem.weight),
      collect_id: movement.id,
      farm_id: movement.farm_id
    };
    if (isCreating) {
      try {
        const response = await axiosApi.post(
          `${url.CREATE_MODAL_MOVEMENTS}/${currentItemConverted.collect_id}`,
          currentItemConverted,
          configHeaderFile
        );
        const { message } = response.data;
        const { data } = response.data;
        toast.success(message);
        const ironFilter = ironsWhenStore.filter(
          (iron) => (iron.id = currentItemConverted.iron_id)
        );
        const IronName = ironFilter[0].name;
        console.log(IronName);

        setLocalMovements((prevMovements) => [
          ...prevMovements,
          { ...currentItemConverted, id: data.id, iron_name: IronName } // Assigning a temporary ID for new item
        ]);
      } catch (error) {
        console.log(error);
        const { message } = error.response.data;
      }
    } else {
      setLocalMovements((prevMovements) =>
        prevMovements.map((item) => (item.id === currentItem.id ? currentItemConverted : item))
      );
      console.log(currentItem);
      try {
        const response = await axiosApi.post(
          `${url.UPDATE_MODAL_MOVEMENTS}/${currentItemConverted.id}`,
          currentItemConverted,
          configHeaderFile
        );
        const { message } = response.data;
        toast.success(message);
      } catch (error) {
        console.log(error);
        const { message } = error.response.data;
      }
    }
    toggleEditModal();
  };

  const formatNumber = (value) => {
    if (!value) return '';
    const cleanedValue = value.replace(/\D/g, '');
    const formattedValue = new Intl.NumberFormat('pt-BR', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(cleanedValue / 100);
    return formattedValue;
  };

  const getAllMovements = useCallback(async () => {
    if (isOpen) {
      await getMovementsByCollectId(movement.id, movement.farm_id);
    }
  }, [movement, isOpen, getMovementsByCollectId]);

  useEffect(() => {
    getAllIron();
    getAllBreed();
  }, []);

  useEffect(() => {
    getAllMovements();
  }, [getAllMovements]);

  useEffect(() => {
    setLocalMovements(movementsByCollect);
  }, [movementsByCollect]);

  const collectName = useMemo(() => {
    if (movement) {
      switch (movement.operation) {
        case MOVIMENTO_OPERACAO_AVALIACAO:
        case ENTRADA:
          return 'create_movement';
        case MOVIMENTO_OPERACAO_TRANSFERENCIA_ENTRADA:
          return 'entry_transfer_movement';
        case MOVIMENTO_OPERACAO_SAIDA:
          return 'exit_movement';
        case MOVIMENTO_OPERACAO_TRANSFERENCIA_SAIDA:
          return 'transfer_movement';
        default:
          return '';
      }
    }
  }, [movement]);

  const dataHoraAtual = movement?.movement_date ? new Date(movement.movement_date) : undefined;
  const dia = dataHoraAtual ? String(dataHoraAtual.getDate()).padStart(2, '0') : '00';
  const mes = dataHoraAtual ? String(dataHoraAtual.getMonth() + 1).padStart(2, '0') : '00';
  const ano = dataHoraAtual ? dataHoraAtual.getFullYear() : '00';

  const handleSaveAsPDF = () => {
    const doc = new jsPDF();
    const logoImg = new Image();
    logoImg.onload = function () {
      const width = 40;
      const aspectRatio = logoImg.height / logoImg.width;
      const height = width * aspectRatio;
      doc.addImage(logoImg, 'PNG', 10, 10, width, height);
      const currentDate = `${dia}/${mes}/${ano}`;
      doc.setFontSize(13);
      doc.text(`Data do movimento: ${currentDate}`, 134, 20);
      doc.text(movement ? movement.name : '', 105, 40, null, null, 'center');
      doc.text('Brincos', 105, 46, null, null, 'center');
      doc.autoTable({
        head: [['Brinco', 'Peso', 'Ferro', 'Nota']],
        body: localMovements.map((item) => [item.earring, item.weight, item.iron_name, item.note]),
        startY: 50
      });
      doc.output('dataurlnewwindow');
      // doc.save(`movements-${ano}-${mes}-${dia}-${movement.id}.pdf`);
    };
    logoImg.src = Logo;
  };

  const selectAnimalHandle = (value) => {
    const animal = animals.find((animal) => animal.id === value);
    const animalMerge = {
      iron_name: animal.iron_name,
      breed_name: animal.breed_name,
      iron_id: animal.iron_id,
      breed_id: animal.breed_id,
      earring_eletric: animal.earring_eletric,
      earring: animal.earring,
      animal_id: value
    };
    setCurrentItem({ ...currentItem, ...animalMerge });
  };
  const handleSaveAsXlsx = () => {
    const workbook = createWorkbook();
    const worksheet = createWorksheet(
      workbook,
      `Movimentos ${movement.movement_name} - ${movement.farm_name}`
    );
    worksheet.columns = [
      { header: 'Brinco', key: 'earring', width: 20 },
      { header: 'Peso', key: 'weight', width: 20 },
      { header: 'Ferro', key: 'iron', width: 10 },
      { header: 'Nota', key: 'note', width: 100 },
      { header: 'Action', key: 'action', width: 50 }
    ];
    localMovements.map((item) =>
      worksheet.addRow({
        earring: item.earring,
        weight: item.weight,
        iron: item.iron_name,
        note: item.note
      })
    );
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      a.href = url;
      a.download = `Movimentos#${movement.id} ${ano}-${mes}-${dia} - ${movement.farm_name}.xlsx`;
      a.click();
      window.URL.revokeObjectURL(url);
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      fullscreen={true}
      toggle={toggle}>
      {(!isLoading && (
        <div className="modal-content container">
          <ModalHeader toggle={toggle}>
            {props.t('Details Collect')} ( {movement?.name} )
          </ModalHeader>
          <ModalBody>
            <Toaster />
            <MediaLibrary
              collect={collectName}
              resource="collect"
              resourceId={movement ? movement.id : 0}
              isFileButtonUpload={false}
              isEditableItemName={false}
              isListOfFile={true}
              isSelectFiles={false}
            />
            <div className="table-responsive">
              <div className="text-sm-end">
                <Button
                  color="success"
                  className="btn-rounded  mb-2 me-2"
                  onClick={handleAddClick}
                  style={{ marginBottom: '10px' }}>
                  {props.t('Add New')}
                </Button>
              </div>
              <Table className="table align-middle table-nowrap">
                <thead>
                  <tr>
                    <th scope="col">{props.t('Earring')}</th>
                    <th scope="col">{props.t('Earring Eletric')}</th>
                    <th scope="col">{props.t('Weight')}</th>
                    <th scope="col">{props.t('Iron')}</th>
                    <th scope="col">{props.t('Note')}</th>
                    <th scope="col">{props.t('Action')}</th>
                  </tr>
                </thead>
                <tbody>
                  {localMovements?.length > 0 &&
                    localMovements?.map((itemMovement) => (
                      <tr key={'id_movement' + itemMovement.id}>
                        <td>{itemMovement.earring}</td>
                        <td>{itemMovement.earring_eletric}</td>
                        <td>{formatPrice(itemMovement.weight)}</td>
                        <td>{itemMovement.iron_name}</td>
                        <td>{itemMovement.note}</td>
                        <td>
                          <Button
                            color="warning"
                            size="sm"
                            onClick={() => handleEditClick(itemMovement)}>
                            {props.t('Edit')}
                          </Button>{' '}
                          <Button
                            color="danger"
                            size="sm"
                            onClick={() => handleDeleteClick(itemMovement.id)}>
                            {props.t('Delete')}
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </ModalBody>

          <ModalFooter>
            <Button type="button" color="secondary" onClick={toggle}>
              {props.t('Close')}
            </Button>
            <Button type="button" color="primary" onClick={handleSaveAsPDF}>
              {props.t('Save as PDF')}
            </Button>
            <Button type="button" color="success" onClick={handleSaveAsXlsx}>
              {props.t('Save as XLSX')}
            </Button>
          </ModalFooter>
        </div>
      )) || (
        <Row>
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '100vh',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              opacity: 0.6
            }}>
            <Spinner />
          </div>
        </Row>
      )}
      <Modal isOpen={editModalOpen} toggle={toggleEditModal}>
        <ModalHeader toggle={toggleEditModal}>
          {isCreating ? props.t('Add Item') : props.t('Edit Item')}
        </ModalHeader>
        <ModalBody>
          {currentItem && (
            <Form>
              {movement.operation == 'E' && (
                <FormGroup>
                  <Label for="earring">{props.t('Earring')}</Label>
                  <Input
                    type="text"
                    id="earring"
                    value={currentItem.earring}
                    onChange={(e) => setCurrentItem({ ...currentItem, earring: e.target.value })}
                  />
                </FormGroup>
              )}
              {movement.operation != 'E' && (
                <FormGroup>
                  <Label for="earring">{props.t('Earring')}</Label>
                  <Select
                    defaultValue={{
                      value: currentItem.animal_id,
                      label: `${currentItem.earring} ${
                        currentItem.earring_eletric ? currentItem.earring_eletric : ''
                      }`
                    }}
                    onChange={(e) => {
                      selectAnimalHandle(e.value);
                    }}
                    options={selectedAnimals}
                    classNamePrefix="select2-selection"
                  />
                </FormGroup>
              )}

              {movement.operation == 'E' && (
                <FormGroup>
                  <Label for="earring">{props.t('Earring Eletric')}</Label>
                  <Input
                    type="text"
                    id="earring"
                    value={currentItem.earring_eletric}
                    onChange={(e) =>
                      setCurrentItem({ ...currentItem, earring_eletric: e.target.value })
                    }
                  />
                </FormGroup>
              )}

              <FormGroup>
                <Label for="weight">{props.t('Weight')}</Label>
                <Input
                  type="text"
                  id="weight"
                  value={formatNumber(currentItem.weight)}
                  onChange={(e) =>
                    setCurrentItem({ ...currentItem, weight: formatNumber(e.target.value) })
                  }
                />
              </FormGroup>

              {movement.operation == 'E' && (
                <FormGroup>
                  <Label for="iron_name">{props.t('Iron')}</Label>
                  <Select
                    defaultValue={{ value: currentItem.iron_id, label: currentItem.iron_name }}
                    onChange={(e) => setCurrentItem({ ...currentItem, iron_id: e.value })}
                    options={optionsIron}
                  />
                </FormGroup>
              )}

              {isCreating && movement.operation == 'E' && (
                <FormGroup>
                  <Label for="breed_name">{props.t('Breed')}</Label>
                  <Select
                    defaultValue={{
                      value: currentItem.breed_id,
                      label: currentItem.breed_name
                    }}
                    onChange={(e) => setCurrentItem({ ...currentItem, breed_id: e.value })}
                    options={optionsBreeds}
                  />
                </FormGroup>
              )}
              <FormGroup>
                <Label for="note">{props.t('Note')}</Label>
                <Input
                  type="textarea"
                  id="note"
                  value={currentItem.note}
                  onChange={(e) => setCurrentItem({ ...currentItem, note: e.target.value })}
                />
              </FormGroup>
            </Form>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleEditModal}>
            {props.t('Cancel')}
          </Button>
          <Button color="primary" onClick={handleSaveEdit}>
            {props.t('Save')}
          </Button>
        </ModalFooter>
      </Modal>
    </Modal>
  );
};

MovementsModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  movement: PropTypes.object
};

export default withTranslation()(MovementsModal);
